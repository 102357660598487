<div class="primary-div">

    <div class="screen-options white-card max-auto" style="margin-bottom: 15px;">
        
        <div class="left-side">
            <div class="screen-title">
                Eventos
            </div>
        </div>

        <div class="right-side" style="position: relative;">
            <input matInput placeholder="Busca..." [(ngModel)]="search" *ngIf="is_admin || is_manager" class="input" 
               autocomplete="off" (ngModelChange)="getAllEvents(1)" matTooltip="Busque pelo nome do evento">

            <a href="/home/event/new" *ngIf="is_admin || is_manager">
                <button class="btn-yellow" mat-raised-button>
                    Novo evento
                </button>
            </a>
        </div>

    </div>

    <mat-accordion class="accordion" *ngIf="is_admin || is_manager">
        <mat-expansion-panel [expanded]="panel1OpenState"
            (opened)="panel1OpenState = true"
            (closed)="panel1OpenState = false">

            <mat-expansion-panel-header>
                <mat-panel-title style="color: gray; font-size: calc(12px + 0.3vw); font-weight: 600;">
                    {{search == null || search == '' ? 'Todos os eventos' : 'Busca'}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="item-panel">
                <app-table *ngIf="loaded.all" [data]="data.all" [type]="'all'"
                    (updateAllRequests)="getAllRequests()"
                    (next)="next($event)" (prev)="prev($event)" ></app-table>

                <div style="padding: 50px; text-align: center;" *ngIf="!loaded.all" class="max-auto">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="color: black;"></span>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

    <div style="height: 10px;" *ngIf="is_admin || is_manager"></div>

    <mat-accordion class="accordion" *ngIf="is_admin || is_manager">
        <mat-expansion-panel>

            <mat-expansion-panel-header>
                <mat-panel-title style="color: gray; font-size: calc(12px + 0.3vw); font-weight: 600;">
                    Próximos eventos
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="item-panel">
                <app-table *ngIf="loaded.new" [data]="data.new" [type]="'new'"
                    (updateAllRequests)="getAllRequests()"
                    (next)="next($event)" (prev)="prev($event)" ></app-table>

                <div style="padding: 50px; text-align: center;" *ngIf="!loaded.new" class="max-auto">
                    <span class="spinner-border spinner-border-sm" role="status" 
                    aria-hidden="true" style="color: black;"></span>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

    <div style="height: 10px;" *ngIf="is_admin || is_manager"></div>

    <mat-accordion class="accordion" *ngIf="is_admin || is_manager">
        <mat-expansion-panel>

            <mat-expansion-panel-header>
                <mat-panel-title style="color: gray; font-size: calc(12px + 0.3vw); font-weight: 600;">
                    Histórico de eventos
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="item-panel">
                <app-table *ngIf="loaded.old" [data]="data.old" [type]="'old'"
                    (updateAllRequests)="getAllRequests()"
                    (next)="next($event)" (prev)="prev($event)" ></app-table>

                <div style="padding: 50px; text-align: center;" *ngIf="!loaded.old" class="max-auto">
                    <span class="spinner-border spinner-border-sm" role="status" 
                    aria-hidden="true" style="color: black;"></span>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="accordion" *ngIf="!is_admin && !is_manager">
        <mat-expansion-panel expanded="true">

            <mat-expansion-panel-header>
                <mat-panel-title style="color: gray; font-size: calc(12px + 0.3vw); font-weight: 600;">
                    Eventos
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="item-panel">
                <app-table *ngIf="loaded.contractor" [data]="data.contractor" [type]="'contractor'"
                    (next)="next($event)" (prev)="prev($event)" ></app-table>

                <div style="padding: 50px; text-align: center;" *ngIf="!loaded.contractor" class="max-auto">
                    <span class="spinner-border spinner-border-sm" role="status" 
                    aria-hidden="true" style="color: black;"></span>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

</div>