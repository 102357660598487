<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> {{title}} - {{vip?.name}} </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div style="text-align: center; font-size: 18px; margin: 10px 0;">
        Evento: {{vip.event.name}} <br>
        Quantidade do pacote: {{vip?.coupons?.length}}
    </div>

    <div style="display: flex; max-width: 540px; margin: 10px auto; width: 100%; flex-wrap: wrap;">
        <input matInput placeholder="insira o email..." [(ngModel)]="email" class="input" 
            autocomplete="off" [readonly]="already_send">

        <button class="btn-yellow" mat-button *ngIf="!already_send" (click)="send()">
            ENVIAR
        </button>

        <button class="btn-yellow" mat-button *ngIf="already_send">
            JÁ FOI ENVIADO
        </button>
    </div>

    <div class="modal-body">

        <div style="width: 100%; text-align: center; margin: 10px auto; font-weight: 600;">Códigos</div>

        <div style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;">
            <div *ngFor="let coupon of data_source.data" class="code">
                {{ coupon.exchangeCode }}
            </div>
        </div>
       

        <!--<table mat-table [dataSource]="data_source" class="table">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Códigos </th>
                <td mat-cell *matCellDef="let data"> {{data.exchangeCode}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>-->
        
    </div>

</div>