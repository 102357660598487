import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  	selector: 'modal-user-detail',
  	templateUrl: './modal-user-detail.component.html',
  	styleUrls: ['./modal-user-detail.component.css']
})

export class ModalUserDetailComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private userService: UserService,
        private datePipe: DatePipe,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    is_manager: boolean = false;
    title: string = "";
    btn_text: string;
    user: User;
    is_new: boolean = true;
    aux_birthDate;

    new_user = {
        birthDate: null,
        cellphone: null,
        cpf: null,
        email: null,
        name: null,
        password: null,
        confirm_password: null,
        user_type: null,
        active: true,
    }
    
    user_types = [
        {name: 'Administrador', apiName: 'admin'},
        {name: 'Contratante', apiName: 'contractor'},
        {name: 'Gerente', apiName: 'manager'},
        {name: 'Usuário', apiName: 'user'},
        {name: 'Vendedor', apiName: 'seller'}
    ]

    validator = new ValidatorUtils()

	ngOnInit() {
        console.log(this.userService.user.user_type);
        
        if(this.userService.user.user_type == 'manager'){
            this.user_types.splice(2,1)
        }

		if(this.user != null){
            if(!this.validator.isEmptyOrNull(this.user.birthDate)){
                this.aux_birthDate = `${this.user.birthDate.substring(8,10)}/${this.user.birthDate.substring(5,7)}/${this.user.birthDate.substring(0,4)}`
                this.new_user.birthDate = `${this.user.birthDate.substring(8,10)}/${this.user.birthDate.substring(5,7)}/${this.user.birthDate.substring(0,4)}`
            }
            
            this.new_user.cellphone = this.user.cellphone;
            this.new_user.cpf = this.user.cpf;
            this.new_user.email = this.user.email;
            this.new_user.name = this.user.name;
            this.new_user.user_type = this.user.user_type;
            this.is_new = false;
        }

        if(this.is_manager){
            this.user_types.splice(0,1)
        }
    }
    
    checkUser(){
        if(this.validator.isEmptyOrNull(this.new_user.name)){
            this.openMessage("Por favor,", "insira o nome do usuário.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.new_user.email)){
            this.openMessage("Por favor,", "insira o email do usuário.", false);
            return false
        }
        else if(!this.validator.isEmptyOrNull(this.new_user.birthDate) && this.new_user.birthDate.length < 10){
            this.openMessage("Por favor,", "insira uma data de nascimento válida (dd/mm/aaaa).", false);
            return false
        }
        else if(!this.validator.isEmptyOrNull(this.new_user.cellphone) && this.new_user.cellphone.length <= 10){
            this.openMessage("Por favor,", "insira um telefone válido.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.new_user.user_type)){
            this.openMessage("Por favor,", "selecione o tipo do usuário.", false);
            return false
        }
        else if(this.is_new && this.validator.isEmptyOrNull(this.new_user.password)){
            this.openMessage("Por favor,", "insira a senha do usuário.", false);
            return false
        }
        else if(this.is_new && this.validator.isEmptyOrNull(this.new_user.confirm_password)){
            this.openMessage("Por favor,", "insira a confirmação de senha do usuário.", false);
            return false
        }
        else if(this.is_new && this.new_user.confirm_password != this.new_user.password){
            this.openMessage("Por favor,", "confirme a senha do usuário.", false);
            return false
        }
        else if(!this.validator.isEmptyOrNull(this.new_user.cpf) && !this.validator.isValidCPF(this.new_user.cpf)){
            this.openMessage("Por favor,", "insira um CPF válido.", false);
            return false
        }
        else{

            let has_bday = !this.validator.isEmptyOrNull(this.new_user.birthDate)
            
            if(has_bday){
                var day = this.new_user.birthDate.substring(0,2)
                var month = this.new_user.birthDate.substring(3,5)
                var year = this.new_user.birthDate.substring(6,10)
                if(day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
                    this.openMessage("Por favor,", "insira uma data de nascimento válida", false)
                    return false
                }
    
                var date = new Date(year, month-1, day)
                var time = new Date().getTime() - date.getTime();
                var time_passed = time / 1000 / 60;
                if(time_passed < 0){
                    this.openMessage("Por favor,", "insira uma data de nascimento válida", false)
                    return false
                }
            }

            let body = {}
            if(this.user == null){
                for(var key in this.new_user){
                    if(key != 'confirm_password' && !this.validator.isEmptyOrNull(this.new_user[key])){
                        if(has_bday && key == 'birthDate'){
                            body[key] = `${year}-${month}-${day}`;
                        }
                        else{
                            body[key] = this.new_user[key]
                        }
                    }
                }
                this.createUser(body)
            }
            else{
                for(var key in this.new_user){
                    if(key != 'confirm_password' && 
                       !this.validator.isEmptyOrNull(this.new_user[key]) && 
                       (this.new_user[key] != this.user[key]))
                    {
                        if(has_bday && key == 'birthDate'){
                            if(this.aux_birthDate != this.new_user[key]){
                                body[key] = `${year}-${month}-${day}`;
                                body['_id'] = this.user._id
                            }
                        }
                        else{
                            body[key] = this.new_user[key]
                            body['_id'] = this.user._id
                        }
                    }
                }
                if(body["_id"] != null){
                    this.editUser(body)
                }
            }

        }
    }

    createUser(body){
        this.openMessage("Por favor, aguarde", "", true)
        this.userService.createUser(body).subscribe( data => {
            this.changeStatus(true, data.user._id)
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

    changeStatus(active, id){
        let body = {'_id': id, 'active': active}
        this.userService.editUser(body).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Criado!", "Usuário criado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.error(error, 'editar')
        })
    }

    editUser(body){
        this.openMessage("Por favor, aguarde", "", true)
        this.userService.editUser(body).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Editado!", "Usuário editado com sucesso.", false)
            this.action.next('update');
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'editar')
        })
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o usuário.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}