import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Events, Event } from "../model/event";
import { UserService } from "./user.service";

@Injectable({
    providedIn: 'root'
})

export class EventsService {

    constructor(
        private http : HttpClient,
        private userService: UserService
    ) { }

    getAllActiveEvents(page, pagesize) : Observable<Events> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<Events>(AppConfig.path + "event/getAllActives/", options)
    }

    getAllEvents(page, pagesize) : Observable<Events> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<Events>(AppConfig.path + "event/getAll/", options)
    }

    

    getFutureEvents(page, pagesize, onlyCreatedByMe) : Observable<Events> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
                .set("shouldReturnOnlyEventsCreatedByMe", onlyCreatedByMe)
        }

        return this.http.get<Events>(AppConfig.path + "event/getFutureEvents/", options)
    }

    getHistoryEvents(page, pagesize, onlyCreatedByMe) : Observable<Events> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
                .set("shouldReturnOnlyEventsCreatedByMe", onlyCreatedByMe)
        }

        return this.http.get<Events>(AppConfig.path + "event/getHistoryEvents/", options)
    }

    getContractorEvents(page, pagesize) : Observable<Events> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
                .set("contractorId", localStorage.getItem('_id'))
        }

        return this.http.get<Events>(AppConfig.path + "event/getContractorEvents/", options)
    }

    searchEvent(page, pagesize, search, onlyCreatedByMe) : Observable<Events> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
                .set("name", search)
                .set("shouldReturnOnlyEventsCreatedByMe", onlyCreatedByMe)
        }
        
        if(onlyCreatedByMe){
            options.params = options.params.append('userId', this.userService.user._id)
        }

        return this.http.get<Events>(AppConfig.path + "event/search/", options)
    }

    getEvent(id) : Observable<Event> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set('_id', id)
        }

        return this.http.get<Event>(AppConfig.path + "event/get/", options)
    }

    getDashBoard(id) : Observable<Event> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            params: new HttpParams()
                .set('eventId', id)
        }

        return this.http.get<Event>(AppConfig.path + "event/getDashBoard/", options)
    }  

    createEvent(request_body): Observable<any>{
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "event/create/", body, { headers })
    }

    editEvent(request_body): Observable<any>{
        console.log(request_body);
        
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "event/edit/", body, { headers })
    }

    deleteEvent(event_id): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('_id', event_id)
        }

        return this.http.get<any>(AppConfig.path + "event/delete/", options)
    }

    getEventParkedQuantity(id, used) : Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('eventId', id)
                .set('used', used)
        }

        return this.http.get<any>(AppConfig.path + "coupon/getCouponsQuantityByEvent/", options)
    }

}