import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { EventsComponent } from './events/events.component';
import { GiveAwayComponent } from './giveaway/giveaway.component';
import { LoginComponent } from './login/login.component';
import { NavbarScreenComponent } from './navbar/navbar.component';
import { SalesComponent } from './sales/sales.component';
import { StatisticsAdminComponent } from './events/statistics-admin/statistics-admin.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UsersComponent } from './users/users.component';
import { StatisticsContractorComponent } from './events/statistics-contractor/statistics-contractor.component';
import { VipComponent } from './vip/vip.component';
import { TicketsComponent } from './tickets/tickets.component';
import {ChangePasswordComponent} from './change-password/change-password.component'
//user/passwordForget/5fb436c078a6ef17e2a93aac

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'user/passwordForget', component: ChangePasswordComponent },

	{ path: 'login', component: LoginComponent },

	{ path: 'home', component: NavbarScreenComponent,
	children: [
		{ path: '', redirectTo: 'events', pathMatch: 'full' },
		{ path: 'events', component: EventsComponent },
		{ path: 'event/edit/:id', component: EventDetailComponent },
		{ path: 'event/new', component: EventDetailComponent },
		{ path: 'event/resume/:id', component: StatisticsAdminComponent },
		{ path: 'event/statistics/:id', component: StatisticsContractorComponent },
		{ path: 'users', component: UsersComponent },
		{ path: 'transactions', component: TransactionsComponent },
		{ path: 'sales', component: SalesComponent },
		{ path: 'giveaway', component: GiveAwayComponent },
		{ path: 'vip', component: VipComponent },
		{ path: 'tickets', component: TicketsComponent },
	]
	},

	{ path:'**', redirectTo: 'home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule { }
