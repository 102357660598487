import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { UserService } from 'src/app/service/user.service';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { ModalChoiceComponent } from 'src/app/modals/modal-choice/modal-choice.component';

@Component({
  	selector: 'app-table',
  	templateUrl: './table.component.html',
  	styleUrls: ['./table.component.css']
})

export class TableComponent {

	constructor( 
		private eventsService: EventsService,
		private modalService: MDBModalService,
		private userService: UserService,
	) { }
    
    displayedColumns: string[] = ['name', 'local', 'address', 'startTime', 'options'];
    data_source = new MatTableDataSource<Event>();

    @Input() data;
	@Input() type;
	@Output() updateAllRequests = new EventEmitter<void>();
	@Output() next = new EventEmitter<string>();
	@Output() prev = new EventEmitter<string>();

	modalRef: MDBModalRef;

	is_admin: boolean = false;
    is_manager: boolean = false;

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;

    ngOnInit(){
        // this.data_source.data = this.data.events.sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0));
        this.data_source.data = this.data.events 
		this.setPage(this.data.page)

		if(this.userService.user != null){
            if(this.userService.user.user_type == "admin")
                this.is_admin = true;
            else if(this.userService.user.user_type == "manager")
                this.is_manager = true;
        }
    }

	setPage(page){
		this.page = page;
		this.count_total = this.data.total;
		this.page_count1 = 1 + ((page-1) * this.page_size)
		if((this.page_count1 + (this.page_size-1)) > this.count_total){
			this.page_count2 = this.count_total;
		}
		else{
			this.page_count2 = this.page_count1 + (this.page_size-1);
		}
	}

	nextFunction(){
		if(this.page_count2 < this.count_total){
			this.data_source.data = []
			this.next.emit(this.type)
		}
	}

	prevFunction(){
		if(this.page > 1){
			this.data_source.data = []
			this.prev.emit(this.type)
		}
	}

	deleteEvent(data){
        this.modalRef = this.modalService.show(ModalChoiceComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: "Tem certeza...",
                text: `de que deseja deletar o evento ${data.name}?`
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "yes"){
                this.openMessage("Por favor, aguarde", "", true)
                this.eventsService.deleteEvent(data._id).subscribe( data => {
                    this.modalRef.hide()
                    this.openMessage("Deletado!", "Evento deletado com sucesso.", false)
                   this.updateAllRequests.emit()
                }, error => {
					this.error(error, 'deletar')
				})
			}
        });
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas";
        this.openMessage(`Erro, não foi possível ${string} o evento.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}