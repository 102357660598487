import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AllVip, Vip } from "../model/vip";

@Injectable({
    providedIn: 'root'
})

export class VipService {

    constructor(private http : HttpClient) { }

    getAllVip(page, pagesize) : Observable<AllVip> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<AllVip>(AppConfig.path + "vip/getAll/", options)
    }

    searchVip(page, pagesize, event, grantor) : Observable<AllVip> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        if(event != null && event != ""){
            options.params = options.params.append("eventName", event)
        }
        if(grantor != null && grantor != ""){
            options.params = options.params.append("grantorName", grantor)
        }

        return this.http.get<AllVip>(AppConfig.path + "vip/search/", options)
    }

    createVip(request_body): Observable<Vip>{
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<Vip>(AppConfig.path + "vip/create/", body, { headers })
    }

    deleteVip(id): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('_id', id)
        }

        return this.http.get<any>(AppConfig.path + "vip/delete/", options)
    }

}