<div class="primary-div">

    <div class="screen-options white-card max-auto">

        <div class="left-side">
            <div class="screen-title">
                Ingressos  
            </div>
        </div>


        <div class="right-side"> 
            <input matInput placeholder="Número do Pedido..." [(ngModel)]="orderNumber" matTooltip="Busque pelo número do pedido"
            class="input" autocomplete="off" (ngModelChange)="getSales(1)">

            <input matInput placeholder="Dono..." [(ngModel)]="search" matTooltip="Busque pelo dono"
                class="input" autocomplete="off" (ngModelChange)="getSales(1)">
 
        </div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <table mat-table [dataSource]="data_source" class="mat-elevation-z8 table-class">

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                <td mat-cell *matCellDef="let data"> {{data.exchangeCode}} </td>
            </ng-container>

            <ng-container matColumnDef="orderNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nº do Pedido </th>
                <td mat-cell *matCellDef="let data"> {{data.orderNumber}} </td>
            </ng-container>


            

            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;"> Dono </th>
                <td mat-cell *matCellDef="let data" style="text-align: center;"> {{data.owner?.email}} </td>
            </ng-container>

            <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Evento </th>
                <td mat-cell *matCellDef="let data"> {{data.eventName}} </td>
            </ng-container>

            <ng-container matColumnDef="car">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Veículo </th>
                <td mat-cell *matCellDef="let data">
                    {{data.vehicle_type == 'car' ? 'Carro' :
                    data.vehicle_type == 'van' ? 'Van' :
                    data.vehicle_type == 'bus' ? 'Ônibus' : data.vehicle_type}}
                </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de ingresso </th>
                <td mat-cell *matCellDef="let data">
                    {{ data.type == 'vip' ? 'Pacote' :
                    data.type == 'giveAway' ? 'Cortesia' :
                    data.type == 'transaction' ? 'Venda online' :
                    data.type == 'sale' ? 'Venda presencial' : data.type }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: left; text-align: start;"> Data
                </th>
              
 
                <td     mat-cell *matCellDef="let data" style="text-align: left; text-align: start;">
                    <span *ngIf="data?.transaction?.receivedDate != null">
                        {{data?.transaction?.receivedDate  | date: 'dd/MM/yyyy HH:mm'}}
                    </span>
                    <span *ngIf="data?.transaction?.receivedDate == null && data?.sale?.createdAt != null">
                         {{data?.sale?.createdAt  | date: 'dd/MM/yyyy HH:mm'}}
                    </span>

                </td>
            </ng-container>

            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let data">
                    <div style="width: 120px; display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
                        <img src="/assets/icons/email.png" class="email-btn" matTooltip="Enviar código por email"
                            (click)="sendEmail(data)">

                        <div class="card_delet" (click)="deletCoupon(data)" *ngIf="type_user != 'manager'">
                            <img src="/assets/icons/delete.png" class="delet-btn" matTooltip="Deletar ingresso">
                        </div>

                        <img src="/assets/icons/plus.png" class="email-btn" matTooltip="Detalhes"
                            (click)="details(data)">

                            <div class="delete-btn" *ngIf="data.type !== 'sale'" matTooltip="Cancelar ingressos" (click)="cancelTransaction(data)">
                                <img src="/assets/icons/cancell.png" class="delete-icon">
                            </div>

                            <div class="unknown-btn" *ngIf="data.type === 'sale'"></div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

        <div class="table-paginator">
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>

            <div style="display: flex;">

                <div class="paginator-button prev" (click)="prev()" matTooltipPosition="above"
                    [matTooltip]="page == 1 ? 'Sem página anterior' : 'Página anterior'"
                    [ngStyle]="{'opacity': page == 1 ? '0.4' : '1' }">
                    <img src="/assets/icons/prev.png" width="12px" height="12px">
                </div>

                <div class="paginator-button next" (click)="next()" matTooltipPosition="above"
                    [matTooltip]="page_count2 < count_total ? 'Pŕoxima página' : 'Sem próxima página'"
                    [ngStyle]="{'opacity': page_count2 < count_total ? '1' : '0.4' }">
                    <img src="/assets/icons/next.png" width="12px" height="12px">
                </div>

                

            </div>
        </div>

    </div>

</div>