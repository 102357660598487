import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { DatePipe } from '@angular/common';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from 'src/app/modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CompressorService } from 'src/app/service/compressor.service';
import { map, expand } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { ModalUserDetailComponent } from 'src/app/users/modal-user-detail/modal-user-detail.component';

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.css']
})

export class EventDetailComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private eventsService: EventsService,
        private datePipe: DatePipe,
        private modalService: MDBModalService,
        private compressor: CompressorService,
        private userService: UserService,
    ) {
        try {
            this.id = this.route.snapshot.paramMap.get('id');
        } catch {
            this.router.navigate(['/home/events'])
        }
    }

    // page
    modalRef: MDBModalRef;
    title: string = "Editar evento";
    button_text: string = "Salvar alterações";
    is_loaded: boolean = false;
    is_new: boolean = false;

    // event
    id;
    event: Event = {
        __v: null,
        _id: null,
        bairro: null,
        capacity: null,
        city: null,
        complement: null,
        createdAt: null,
        creator: null,
        description: null,
        endTime: null,
        image: null,
        local: null,
        localNumber: null,
        name: null,
        startTime: null,
        street: null,
        updatedAt: null,
        usersThatParked: null,
        carPrice: null,
        carSpots: null,
        vanPrice: null,
        vanSpots: null,
        busPrice: null,
        busSpots: null,
        parkedCars: null,
        parkedVans: null,
        parkedBus: null,
        busSoldTickets: null,
        carSoldTickets: null,
        vanSoldTickets: null,
        s3ImageName: null,
        busGiveAwayTicketQuantity: null,
        busSaleTicketQuantity: null,
        busTransactionTicketQuantity: null,
        carGiveAwayTicketQuantity: null,
        carSaleTicketQuantity: null,
        carTransactionTicketQuantity: null,
        vanGiveAwayTicketQuantity: null,
        vanSaleTicketQuantity: null,
        vanTransactionTicketQuantity: null,
        contractor: null,
        busVipTicketQuantity: null,
        carVipTicketQuantity: null,
        vanVipTicketQuantity: null,
        isPrivate: false,
        isPartnerDiscountAvailable: true,
        partnerDiscountPercentage: 0.5
    };
    static_event;

    // new
    startDate;
    startHour;
    endDate;
    endHour;

    contractors: User[] = [];
    contractor;

    //image
    send_image;
    compressed_image = []

    validator = new ValidatorUtils()

    ngOnInit() {
        if (localStorage.getItem('token') == null) {
            this.router.navigate(['']);
        }
        else {
            if (this.router.url == '/home/event/new') {
                this.is_new = true;
                this.title = "Adicionar evento";
                this.button_text = "Criar evento";
                this.is_loaded = true;
            }
            this.getContractors()
        }
        this.event.creator = localStorage.getItem('_id')

    }

    formatLabel(value: number) {
        return Math.round(value * 100) + '%';
    }

    getContractors() {
        this.contractors = []
        this.userService.getAllUsers(1, 100, 'contractor').subscribe(data => {
            this.contractors = data.users;
            if (!this.is_new)
                this.getEvent()
        })
    }

    getEvent() {
        this.eventsService.getEvent(this.id).subscribe(data => {
            this.event = data;

            //just copy was connecting event and static event, so we need to do a deep clone
            this.static_event = JSON.stringify(data);
            this.static_event = JSON.parse(this.static_event);

            let startDate = new Date(this.event.startTime)
            let endDate = new Date(this.event.endTime)
            this.startDate = startDate
            this.startHour = this.datePipe.transform(startDate, 'HHmm')
            this.endDate = endDate
            this.endHour = this.datePipe.transform(endDate, 'HHmm')

            this.contractor = this.contractors.filter(el => el._id == this.event.contractor)[0]

            this.is_loaded = true;
        })
    }

    convertDateToUTC(date) {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    checkEvent() {

        if (this.validator.isEmptyOrNull(this.event.name)) {
            this.openMessage("Por favor,", "insira o nome do evento.", false);
            return false
        }
        else if (this.validator.isEmptyOrNull(this.event.local)) {
            this.openMessage("Por favor,", "insira o local do evento.", false);
            return false
        }
        else if (this.event.capacity == null) {
            this.openMessage("Por favor,", "insira a capacidade do evento.", false);
            return false
        }
        else if (this.event.carPrice == null) {
            this.openMessage("Por favor,", "insira o preço da vaga para carros.", false);
            return false
        }
        else if (this.event.carSpots == null) {
            this.openMessage("Por favor,", "insira a quantidade de vagas para carros.", false);
            return false
        }
        else if (this.event.vanPrice == null) {
            this.openMessage("Por favor,", "insira o preço da vaga para vans.", false);
            return false
        }
        else if (this.event.vanSpots == null) {
            this.openMessage("Por favor,", "insira a quantidade de vagas para vans.", false);
            return false
        }
        else if (this.event.busPrice == null) {
            this.openMessage("Por favor,", "insira o preço da vaga para ônibus.", false);
            return false
        }
        else if (this.event.busSpots == null) {
            this.openMessage("Por favor,", "insira a quantidade de vagas para ônibus.", false);
            return false
        }
        else if (this.validator.isEmptyOrNull(this.startDate)) {
            this.openMessage("Por favor,", "insira uma data de início.", false);
            return false
        }
        else if (this.validator.isEmptyOrNull(this.endDate)) {
            this.openMessage("Por favor,", "insira uma data de fim.", false);
            return false
        }
        else if (this.validator.isEmptyOrNull(this.startHour) ||
            this.startHour.length < 4 || /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.startHour) == false) {
            this.openMessage("Por favor,", "Insira um horário de início válido (hh:mm)", false)
            return false;
        }
        else if (this.validator.isEmptyOrNull(this.endHour) ||
            this.endHour.length < 4 || /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/.test(this.endHour) == false) {
            this.openMessage("Por favor,", "Insira um horário de fim válido (hh:mm)", false)
            return false;
        }
        else if (this.is_new && this.send_image == null) {
            this.openMessage("Por favor,", "Insira a imagem do evento", false)
            return false;
        }
        else if (this.contractor == null || this.contractor == "novo") {
            this.openMessage("Por favor,", "Insira o contratante do evento", false)
            return false;
        }
        else {
            var date1 = this.startDate
            date1.setHours(this.startHour.substring(0, 2), this.startHour.substring(2, 4))

            var date2 = this.endDate
            date2.setHours(this.endHour.substring(0, 2), this.endHour.substring(2, 4))

            var time1 = new Date().getTime() - date1.getTime();
            var time_passed = time1 / 1000 / 60;
            var time2 = date1.getTime() - date2.getTime();
            var time_between = time2 / 1000 / 60;

            if (time_passed > 0) {
                this.openMessage("Por favor,", "insira uma data de início válida.", false);
                return false
            }
            else if (time_between > 0) {
                this.openMessage("Por favor,", "insira uma data de fim válida.", false);
                return false
            }

            let body = {}
            if (this.is_new) {

                var aux1 = this.datePipe.transform(date1, "yyyy-MM-dd:HH:mm:ss")
                this.event.startTime = aux1
                var aux2 = this.datePipe.transform(date2, "yyyy-MM-dd:HH:mm:ss")
                this.event.endTime = aux2

                this.event.image = this.send_image[0];

                for (var key in this.event) {
                    if (!this.validator.isEmptyOrNull(this.event[key]))
                        body[key] = this.event[key]
                }
                body['contractorId'] = this.contractor._id
                this.createEvent(body)

            }
            else {
                date1 = this.convertDateToUTC(date1)
                date2 = this.convertDateToUTC(date2)

                var aux1 = this.datePipe.transform(date1, "yyyy-MM-ddTHH:mm:ss")
                this.event.startTime = `${aux1}.000Z`
                var aux2 = this.datePipe.transform(date2, "yyyy-MM-ddTHH:mm:ss")
                this.event.endTime = `${aux2}.000Z`

                for (var key in this.event) {                  
                    
                    if(key == 'isPartnerDiscountAvailable'){
                        console.log(this.event[key]);
                        body[key] = this.event[key]
                        body["_id"] = this.event._id
                    }

                    if (key != "location" && key != "usersThatParked" && !this.validator.isEmptyOrNull(this.event[key])) {
                        
                        if (this.event[key] != this.static_event[key]) {
                            if (key == 'image' && this.send_image.length > 0) {
                                body[key] = this.send_image[0]
                            }
                            else {
                                body[key] = this.event[key]
                            }
                            body["_id"] = this.event._id
                        }
                        else if (key == 'contractor' && this.event[key] != this.contractor._id) {
                            body["_id"] = this.event._id
                            body['contractor'] = this.contractor._id
                        }
                    }
                    else if (key == 'isPrivate' && this.event[key] != this.static_event[key]) {
                        body[key] = this.event[key]
                        body["_id"] = this.event._id
                    }
                    else if (key == "description" && !this.validator.isEmptyOrNull(this.static_event[key])) {
                        if (this.static_event[key] != this.event[key])
                            body[key] = this.event[key] == null ? "" : this.event[key]
                    }
                }

                if (body["_id"] != null) {
                    console.log(body);
                    
                    this.editEvent(body)
                }

            }
        }
    }

    createEvent(body) {
        this.openMessage("Por favor, aguarde", "", true)
        this.eventsService.createEvent(body).subscribe(data => {
            console.log(data)
            this.modalRef.hide()
            this.openMessage("Criado!", "Evento criado com sucesso.", false)
            this.router.navigate(['/home/events'])
        }, error => {
            this.modalRef.hide()
            this.error(error, 'criar')
        })
    }

    editEvent(body) {
        this.openMessage("Por favor, aguarde", "", true)
        this.eventsService.editEvent(body).subscribe(data => {
            console.log(data)
            this.modalRef.hide()
            this.openMessage("Salvo!", "Alterações salvas com sucesso.", false)
            this.static_event = data;
            this.router.navigate(['/home/events'])
        }, error => {
            this.modalRef.hide()
            this.error(error, 'editar')
        })
    }

    error(error, string) {
        var msg = ""

        if (error.error != null && error.error.error != null && error.error.error.length < 80)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas";

        this.openMessage(`Erro, não foi possível ${string} o evento.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean) {
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

    async changePhoto(event) {

        if (event.target.files && event.target.files[0]) {
            var target = event.target.files
            const compress = this.recursiveCompress(target[0], 0, target).pipe(
                expand(res => {
                    return res.index > res.array.length - 1
                        ? EMPTY
                        : this.recursiveCompress(target[res.index], res.index, target);
                }),
            );
            compress.subscribe(res => {
                if (res.index > res.array.length - 1) {
                    this.send_image = this.compressed_image
                    this.compressed_image = []
                    this.event.image = target[0].name
                }
            });
        }

    }

    recursiveCompress = (image: File, index, array) => {
        return this.compressor.compress(image).pipe(
            map(response => {

                this.compressed_image.push(response)
                return {
                    data: response,
                    index: index + 1,
                    array: array,
                };
            }),
        );
    }

    changeContractor(value) {
        if (value == "novo") {
            this.createUser()
        }
    }

    createUser() {
        this.modalRef = this.modalService.show(ModalUserDetailComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: "Criar usuário",
                btn_text: "Criar",
                user: null
            }
        })
        this.modalRef.content.action.subscribe((result: any) => {
            if (result == "update") {
                this.getContractors()
            }
        });
    }

}
