<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title">
            <b> {{title}} </b>
        </h1>

    </div>

    <div class="modal-body" *ngIf="text != null && text != ''">
        {{text}}
    </div>

    

</div>