import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { CouponService } from '../service/coupon.service';
import { Coupon } from '../model/giveaway';
import { ModalSendTicketComponent } from './modal-send-ticket/modal-send-ticket.component';
import { ModalDetailsComponent } from './modal-details/modal-details.component'
import { TransactionsService } from '../service/transactions.service';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';

import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { UserService } from '../service/user.service';

@Component({
	selector: 'app-tickets',
	templateUrl: './tickets.component.html',
	styleUrls: ['./tickets.component.css']
})

export class TicketsComponent {

	constructor(
		private router: Router,
		private couponService: CouponService,
		private transactionsService: TransactionsService,

		private modalService: MDBModalService,
		private userService: UserService
	) { }

	modalRef: MDBModalRef
	orderNumber: string
	displayedColumns: string[] = ['code', 'orderNumber', 'owner', 'event', 'car', 'type', 'date', 'option'];
	data_source = new MatTableDataSource<Coupon>();

	list_tickets = []
	search: string;
	/* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
	page_size = 10;

	is_loaded: boolean = false;

	type_user: string = '';


	ngOnInit() {
		this.type_user = this.userService.user.user_type

		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
		}
		else {
			this.getSales(1)
		}
	}

	getSales(page) {
		 

		this.couponService.getAllCoupons(page, this.page_size, this.search, this.orderNumber).subscribe(data => {			 
			this.setData(data, page)
		}, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}

	setData(data, page) {
		this.is_loaded = true;
		this.list_tickets = []
		data.coupons.forEach((coupon) => {
			if (coupon.type != 'giveAway') {
				
				this.list_tickets.push(coupon)
			}
		});

		this.data_source.data = this.list_tickets;

		// pagination calc
		this.page = page;
		this.count_total = data.total;
		this.page_count1 = 1 + ((page - 1) * this.page_size)
		if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
			this.page_count2 = this.count_total;
		}
		else {
			this.page_count2 = this.page_count1 + (this.page_size - 1);
		}
	}


 

	next() {
		if (this.page_count2 < this.count_total && this.is_loaded) {
			this.data_source.data = []
			this.is_loaded = false;
			this.getSales(this.page + 1)
		}
	}

	prev() {
		if (this.page > 1 && this.is_loaded) {
			this.data_source.data = []
			this.is_loaded = false;

			 

			this.getSales(this.page - 1)
		}
	}

	convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
	}

	details(coupon) {
		this.modalRef = this.modalService.show(ModalDetailsComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog modal-lg',
			containerClass: '',
			animated: false,
			data: {
				title: "Enviar código por email",
				coupon: coupon
			}
		})
	}


	sendEmail(coupon) {
		this.modalRef = this.modalService.show(ModalSendTicketComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog modal-lg',
			containerClass: '',
			animated: false,
			data: {
				title: "Enviar código por email",
				coupon: coupon
			}
		})
	}

	deletCoupon(coupon) {
		console.log(coupon);

		this.modalRef = this.modalService.show(ModalChoiceComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-dialog modal-sm',
			containerClass: '',
			animated: false,
			data: {
				title: "Deletar Ingresso",
				text: "Deseja deletar o ingresso?"
			}
		})
		this.modalRef.content.action.subscribe((result: any) => {
			if (result == 'yes') {

				if (coupon.type == "vip")
					this.deleteVip(coupon._id)

				else if (coupon.type == "giveaway")
					this.deleteGiveAway(coupon._id)

				else if (coupon.type == "sale")
					this.deleteSale(coupon._id)

				else if (coupon.type == "transaction")
					this.deleteTransaction(coupon._id)
			}
		});
	}

	cancelTransaction(obj) {
	 

	 
		this.modalRef = this.modalService.show(ModalChoiceComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: false,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				title: "Tem certeza...",
				text: `de que deseja cancelar e reembolsar o valor do ingresso ${obj.eventName}?`
			}
		})

		this.modalRef.content.action.subscribe((result: any) => {
			if (result == "yes") {
				console.log({
					ticketId: obj._id,
					transactionId: obj.transaction._id
				})
				this.cancelCreditCardTransaction(obj.transaction._id, obj._id)
 			}
		});	 
	}

	openMessage(title, text, bgclick: boolean) {
		this.modalRef = this.modalService.show(ModalMessageComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: bgclick,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				title: title,
				text: text
			}
		})
	}

	cancelCreditCardTransaction(transactionId, ticketId) {	 

this.transactionsService.cancelCreditCardTransactionPartial(transactionId, ticketId).subscribe(data => {
 

	this.openMessage("Sucesso!", "venda cancelada! ", false)
	
	this.getSales(1)
}, error => {
	this.getSales(1)
	console.log(error)
	// console.log(error.error.error)

	if(error.status == 491 || error.status == 492){
		this.openMessage("Erro", "transação nao está disponivel ", false)
	}
	else{
		this.openMessage("Erro", "erro no cancelamento da venda! ", false)
	}
})
}

	deleteVip(id) {
		this.couponService.VipDelet(id).subscribe(data => {
			this.getSales(1)
		}, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}

	deleteGiveAway(id) {
		this.couponService.GiveAwayDelet(id).subscribe(data => {
			this.getSales(1)
		}, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}

	deleteSale(id) {
		this.couponService.SaleDelet(id).subscribe(data => {
			this.getSales(1)
		}, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}

	deleteTransaction(id) {
		this.couponService.TransactionDelet(id).subscribe(data => {
			this.getSales(1)
		}, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}
}
