import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { EventsService } from '../service/events.service';
import { UserService } from '../service/user.service';

@Component({
  	selector: 'app-events',
  	templateUrl: './events.component.html',
  	styleUrls: ['./events.component.css']
})

export class EventsComponent {

	constructor( 
		private router: Router,
		private eventsService: EventsService,
        private userService: UserService
	) { }
    
    is_loaded: boolean = false;
    is_admin: boolean = false;
    is_manager: boolean = false;

    search: string = "";
    page_size: number = 10;

    panel1OpenState: boolean = true;

    data = {all: null, new: null, old: null, contractor: null};
    pages = {all: 1, new: 1, old: 1, contractor: 1};
    loaded = {all: false, new: false, old: false, contractor: false};

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
        }
        else if(this.userService.user != null){
            if(this.userService.user.user_type == "admin"){
                this.is_admin = true;
                this.getAllRequests()
            }
            else if(this.userService.user.user_type == "manager"){
                this.is_manager = true;
                this.getAllRequests()
            }
            else{
                this.getContractorEvents(1)
            }
        }
    }

    getAllRequests(){
        this.getAllEvents(1)
        this.getNewEvents(1)
        this.getOldEvents(1)
    }

    next(event){
        if(event == 'all'){
            this.getAllEvents(this.pages.all + 1)
        }
        else if(event == 'new'){
            this.getNewEvents(this.pages.new + 1)
        }
        else if(event == 'old'){
            this.getOldEvents(this.pages.old + 1)
        }
        else if(event == 'contractor'){
            this.getContractorEvents(this.pages.old + 1)
        }
    }

    prev(event){
        if(event == 'all'){
            this.getAllEvents(this.pages.all - 1)
        }
        else if(event == 'new'){
            this.getNewEvents(this.pages.new - 1)
        }
        else if(event == 'old'){
            this.getOldEvents(this.pages.old - 1)
        }
        else if(event == 'contractor'){
            this.getContractorEvents(this.pages.old - 1)
        }
    }

    getAllEvents(page){
        if(this.search != null && this.search != ""){
            this.panel1OpenState = true;
        }
        
        this.loaded.all = false;
        this.eventsService.searchEvent(page, this.page_size, this.search, this.is_manager).subscribe( data => {
            this.data.all = data;
            this.loaded.all = true;
            this.pages.all = page;
        }, error => {
            localStorage.removeItem('token')
            this.router.navigate([''])
        })
    }

    getNewEvents(page){
        this.loaded.new = false;
        this.eventsService.getFutureEvents(page, this.page_size, this.is_manager).subscribe( data => {
            this.data.new = data;
            this.loaded.new = true;
            this.pages.new = page;
        }, error => {
            localStorage.removeItem('token')
            this.router.navigate([''])
        })
    }

    getOldEvents(page){
        this.loaded.old = false;
        this.eventsService.getHistoryEvents(page, this.page_size, this.is_manager).subscribe( data => {
            this.data.old  = Object.assign({}, data)

            this.loaded.old = true;
            this.pages.old = page;
        }, error => {
            localStorage.removeItem('token')
            this.router.navigate([''])
        })
    }

    getContractorEvents(page){
        this.loaded.contractor = false;
        this.eventsService.getContractorEvents(page, this.page_size).subscribe( data => {
            this.data.contractor = data;
            this.loaded.contractor = true;
            this.pages.contractor = page;
        }, error => {
            localStorage.removeItem('token')
            this.router.navigate([''])
        })
    }

}
