<div class="primary-div">

    <div class="screen-options white-card max-auto">
        
        <div class="left-side">
            <div class="screen-title">
                Vendas online 
            </div>
        </div>

        <div class="right-side">

            <div style="width: 270px; margin-right: 6px;" class="div-date">
                <input matInput [placeholder]="range.value.start == null ? 'De - Até' : ''" 
                    class="input" readonly style="width: 270px;">

                <mat-form-field class="input-date" color="accent"  matTooltip="Busca por data (De - Até)">

                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="" readonly 
                            (click)="picker.open()" (dateChange)="getTransactions(1, true)">
                        <input matEndDate formControlName="end" placeholder="" readonly 
                            (click)="picker.open()" (dateChange)="getTransactions(1, true)">
                    </mat-date-range-input>

                    <mat-datepicker-toggle *ngIf="range.value.start == null || range.value.end == null"
                        matSuffix [for]="picker" ></mat-datepicker-toggle>

                    <mat-datepicker-toggle matSuffix (click)="clearDate($event)" 
                        *ngIf="range.value.start != null && range.value.end != null">
                        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                    </mat-datepicker-toggle>
                    
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <input matInput placeholder="Busca por evento..." [(ngModel)]="search_event" class="input"
                matTooltip="Busque pelo nome do evento" autocomplete="off" (ngModelChange)="getTransactions(1, true)">

            <input matInput placeholder="Busca por usuário..." [(ngModel)]="search_user" class="input"
                matTooltip="Busque pelo nome do usuário" autocomplete="off" (ngModelChange)="getTransactions(1, true)">
        </div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <table mat-table [dataSource]="data_source" class="mat-elevation-z8 table-class">
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Usuário </th>
                <td mat-cell *matCellDef="let data"> 
                    {{data.userName}} <br>
                    {{data.userThatMade?.email}}
                </td>
            </ng-container>

            <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Evento </th>
                <td mat-cell *matCellDef="let data"> {{data.eventName}} </td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Valor </th>
                <td mat-cell *matCellDef="let data"> {{convertMoney(data.amount/100)}} </td>
            </ng-container>

           
    
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Tipo de pagamento </th>
                <td mat-cell *matCellDef="let data"> 
                    {{  data.transactionType == 'CreditCard' ? 'Cartão de crédito' : data.transactionType }} 
                </td>
            </ng-container>

            <ng-container matColumnDef="partner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Desc. de Sócio </th>
                <td mat-cell *matCellDef="let data"> 
                    {{  data.haveClubPartnerDiscount ? 'Sim' : 'Não' }} 
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Bandeira e Cartão </th>
                <td mat-cell *matCellDef="let data"> 
                    {{data.creditCardBrand}} <br>
                    {{data.creditCardNumber}} 
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Data </th>
                <td mat-cell *matCellDef="let data">
                    {{data.receivedDate | date: 'dd/MM/yyyy HH:mm' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="authorizationCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Cód. Autorização </th>
                <td mat-cell *matCellDef="let data"> 
                     {{data.authorizationCode }}                   
                </td>
            </ng-container>

             

            <ng-container matColumnDef="tId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Id Transação </th>
                <td mat-cell *matCellDef="let data"> 
                     {{data.tId }} 
                   
                </td>
            </ng-container>


            <ng-container matColumnDef="proofOfSale">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Prova da venda </th>
                <td mat-cell *matCellDef="let data"> 
                     {{data.proofOfSale }} 
                   
                </td>
            </ng-container>

           


            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
                <td mat-cell *matCellDef="let data"> 
                    Capturada: {{data.capture ? 'Sim' : 'Não'}} <br> 
                    Cancelada: {{data.isCanceled ? 'Sim' : 'Não'}} 
                </td>
            </ng-container>

           

            

             
            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let data"> 
                    <div class="card_row">
                        <div class="delete-btn" matTooltip="Ver ingressos" (click)="getTransactionsCoupon(data)">
                            <img src="/assets/icons/tickets.png" class="delete-icon">
                        </div>
    
                        <div class="delete-btn" matTooltip="Cancelar" (click)="cancelTransaction(data)">
                            <img src="/assets/icons/cancell.png" class="delete-icon">
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

        <div class="table-paginator">
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
            
            <div style="display: flex;">
                
                <div class="paginator-button prev" (click)="prev()" matTooltipPosition="above"
                [matTooltip]="page == 1 ? 'Sem página anterior' : 'Página anterior'"
                [ngStyle]="{'opacity': page == 1 ? '0.4' : '1' }">
                    <img src="/assets/icons/prev.png" width="12px" height="12px">
                </div>
                
                <div class="paginator-button next" (click)="next()" matTooltipPosition="above"
                [matTooltip]="page_count2 < count_total ? 'Pŕoxima página' : 'Sem próxima página'"
                [ngStyle]="{'opacity': page_count2 < count_total ? '1' : '0.4' }">
                    <img src="/assets/icons/next.png" width="12px" height="12px">
                </div>
                
			</div>
        </div>

    </div>

</div>