import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Sales } from "../model/sale";

@Injectable({
    providedIn: 'root'
})

export class SalesService {

    constructor(private http : HttpClient) { }

    getAllSales(page, pagesize) : Observable<Sales> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<Sales>(AppConfig.path + "sale/getAll/", options)
    }

    searchSales(page, pagesize, event, seller, greater, lower) : Observable<Sales> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        if(event != null && event != ""){
            options.params = options.params.append("eventName", event)
        }
        if(seller != null && seller != ""){
            options.params = options.params.append("sellerName", seller)
        }
        if(greater != null && greater != "" && lower != null && lower != ""){
            options.params = options.params.append("gte", greater)
            options.params = options.params.append("lt", lower)
        }

        return this.http.get<Sales>(AppConfig.path + "sale/search/", options)
    }

}