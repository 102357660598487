<div class="screen">
    <div class="div-login">

        <div class="logo-img">
            <img src="../../assets/images/Fast.png">
        </div>

        <div style="color: #FFD500; font-size: 23px; font-weight: 500; margin: 20px auto;">
            Alterar senha
        </div>

        <div style="height: 20px;"></div>

        <div class="div-input">
            <input matInput type="password" [(ngModel)]="password" placeholder="Nova senha"
            [ngStyle]="{'border-color': warningMap.get('password')? 'red' : ''}">
        </div>

        <div class="div-input">
            <input matInput type="password" (keyup.enter)="checkInput()" [(ngModel)]="confirm_password" placeholder="Confirmar senha"
            [ngStyle]="{'border-color': warningMap.get('confirm_password')? 'red' : ''}">

        </div>
        <mat-error>{{msg_error}}</mat-error>
        <div class="on-success" *ngIf="success_message">{{success_message}}</div>
        <div class="on-redirect" *ngIf="success_message">redirecionando para o login...</div>
        
        <button class="btn-yellow" (click)="checkInput()" mat-raised-button  style="margin-top: 20px;">
            Enviar
            <span *ngIf="loading" class="spinner-border spinner-border-sm" 
            style="margin-left: 5px; color: black;" role="status" aria-hidden="true"></span>
        </button>
    </div>
</div>