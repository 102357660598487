import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationExtras } from "@angular/router";
import { UserService } from '../service/user.service';
import { MDBModalRef, MDBModalService, NavbarComponent } from 'angular-bootstrap-md'
import { User } from '../model/user';
import { ModalChangePasswordComponent } from '../modals/modal-change-password/modal-change-password.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarScreenComponent{

    @ViewChild('navbar') navbar: NavbarComponent;

    constructor( 
        private router: Router, 
        private userService: UserService,
        private modalService: MDBModalService, 
    ){
        /* catch page change event */
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                if (this.navbar != null && this.navbar.shown) {
                    this.navbar.hide();
                }
                
                this.checkUser()
                this.url = this.router.url
                //console.log(this.url);
            }
        });
    }
    
    user: User;
    is_admin: boolean = false;
    is_manager: boolean = false;
    loaded: boolean = false;
    url: string;

    modalRef: MDBModalRef;

    ngOnInit() {
        this.checkUser()
    }

    logout(){
        localStorage.removeItem('token')
        localStorage.removeItem('_id')
        this.router.navigate(['/login'])
    }

    changePassword(){
        this.modalRef = this.modalService.show(ModalChangePasswordComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
				user_id: this.user._id
            }
        })
    }

    checkUser(){
        const id = localStorage.getItem('_id')
        if(localStorage.getItem('token') == null || id == null){
            this.router.navigate(['/login'])
        }
        else if(this.user == null) {

            this.userService.getUser(id).subscribe( data => {
                this.user = data;
                this.userService.user = data;
                this.loaded = true;
                if(data.user_type != "admin" && data.user_type != "contractor" && data.user_type != "manager"){
                    this.logout()
                }
                else{
                    if(data.user_type == "contractor"){
                        if(this.router.url != '/home/events' && 
                            this.router.url.substring(0,22) != "/home/event/statistics" ){
                            this.logout()
                        }
                    }
                    else if(data.user_type == "manager"){
                        this.is_manager = true;
                    }
                    else{
                        this.is_admin = true;
                    }
                }
            }, error => {
                this.logout()
            })

        }
    }

}
