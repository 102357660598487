import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Coupon } from "../model/giveaway";

@Injectable({
    providedIn: 'root'
})

export class CouponService {

    constructor(private http : HttpClient) { }

    exchangeCoupon(email, code, event_name, event_image): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('email', email)
                .set('exchangeCode', code)
                .set('eventName', event_name)
                .set('eventImage', event_image)
        }

        return this.http.get<any>(AppConfig.path + "coupon/exchangeCoupon/", options)
    }

    exchangeCoupons(email, codes, event_name, event_image): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('email', email)
                .set('exchangeCodes', codes)
                .set('eventName', event_name)
                .set('eventImage', event_image)
        }

        return this.http.get<any>(AppConfig.path + "coupon/exchangeCoupons/", options)
    }

    getAllCoupons(page, pagesize, search, orderNumber) : Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
        }

        return this.http.get<any>(AppConfig.path + `coupon/getAll?limit=${pagesize}&page=${page}${search !== null && search !== '' && search !== undefined ? `&ownerEmail=${search}` : ''}${orderNumber !== null && orderNumber !== ''&& orderNumber !== undefined ? `&orderNumber=${orderNumber}` : ''}`, options)
    }

    VipDelet(id) : Observable<Coupon> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<Coupon>(AppConfig.path + "vip/delete", options)
    }

    GiveAwayDelet(id) : Observable<Coupon> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<Coupon>(AppConfig.path + "giveaway/delete", options)
    }

    SaleDelet(id) : Observable<Coupon> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<Coupon>(AppConfig.path + "sale/delete", options)
    }

    TransactionDelet(id) : Observable<Coupon> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<Coupon>(AppConfig.path + "transaction/delete", options)
    }
}