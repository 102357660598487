import { Component, Input } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  	selector: 'modal-coupons',
  	templateUrl: './modal-coupons.component.html',
  	styleUrls: ['./modal-coupons.component.css']
})

export class ModalCouponsComponent {

	constructor(public modalRef: MDBModalRef) { }

	action: Subject<any> = new Subject();
	coupons = []

	ngOnInit() {
		
	}

}