import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { Coupon, GiveAway } from 'src/app/model/giveaway';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CouponService } from 'src/app/service/coupon.service';

@Component({
  	selector: 'modal-giveaway-coupons',
  	templateUrl: './modal-giveaway-coupons.component.html',
  	styleUrls: ['./modal-giveaway-coupons.component.css']
})

export class ModalGiveAwayCouponsComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private couponService: CouponService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    is_loaded: boolean = false;

    displayedColumns: string[] = ['code', 'email', 'send'];
    data_source = new MatTableDataSource<Coupon>();

    giveaway: GiveAway;

    validator = new ValidatorUtils();
    email: string[] = []

	ngOnInit() {
        this.data_source.data = this.giveaway.coupons
    }

    send(coupon, index){
        if(this.validator.isEmptyOrNull(this.email[index])){
            this.openMessage('Por favor,', 'insira o email', false)
            return false
        }
        else{
            this.openMessage("Por favor, aguarde", "", true)
            this.couponService.exchangeCoupon(this.email[index], coupon.exchangeCode,
                this.giveaway.event.name, this.giveaway.event.image).subscribe( data => {
                    this.modalRef2.hide()
                    this.giveaway.coupons[index].alreadySentToClientEmail = true;
                    this.openMessage("Enviado!", "Cupom enviado com sucesso.", false)
            }, error => {
                this.modalRef2.hide()
                this.error(error, 'enviar')
            })
        }
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o cupom.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}