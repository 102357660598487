<div class="graphic">
    <canvas baseChart #chart1
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType"
        [colors]="graphicColor">
    </canvas>
</div>
