<div style="padding: 0; overflow-x: auto;" class="max-auto">

    <table mat-table [dataSource]="data_source" class="mat-elevation-z8 table-class">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Nome </th>
            <td mat-cell *matCellDef="let data"> {{data.name}} </td>
        </ng-container>

        <ng-container matColumnDef="local">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Local </th>
            <td mat-cell *matCellDef="let data"> {{data.local}} </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Endereço </th>
            <td mat-cell *matCellDef="let data"> {{data.street}}, {{data.bairro}}, {{data.complement}} <br> {{data.city}} </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Data </th>
            <td mat-cell *matCellDef="let data"> {{data.startTime | date: 'dd/MM/yyyy HH:mm' }} </td>
        </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="{'width': is_admin || is_manager ? '150px' : '50px' }"> </th>
            <td mat-cell *matCellDef="let data" [ngStyle]="{'width': is_admin || is_manager ? '150px' : '50px' }"> 
                <div style="display: flex;">
                    <a href="/home/event/statistics/{{data._id}}" matTooltip="Estatísticas">
                        <img src="/assets/icons/bars2.png" class="edit-icon" style="margin-right: 8px;">
                    </a>

                    <!-- <a href="/home/event/resume/{{data._id}}" matTooltip="Resumo" *ngIf="is_admin || is_manager">
                        <img src="/assets/icons/eye2.png" class="edit-icon" style="margin-right: 8px;">
                    </a> -->

                    <a href="/home/event/edit/{{data._id}}" matTooltip="Editar evento" *ngIf="is_admin || is_manager">
                        <img src="/assets/icons/edit.png" class="edit-icon">
                    </a>

                    <div class="delete-btn" matTooltip="Deletar evento?" (click)="deleteEvent(data)" *ngIf="is_admin || is_manager">
                        <img src="/assets/icons/delete.png" class="delete-icon">
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
        <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
    </table>

    <div class="table-paginator">
        <div>
            Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
        </div>
        
        <div style="display: flex;">
            
            <div class="paginator-button prev" (click)="prevFunction()" matTooltipPosition="above"
            [matTooltip]="page == 1 ? 'Sem página anterior' : 'Página anterior'"
            [ngStyle]="{'opacity': page == 1 ? '0.4' : '1' }">
                <img src="/assets/icons/prev.png" width="12px" height="12px">
            </div>
            
            <div class="paginator-button next" (click)="nextFunction()" matTooltipPosition="above"
            [matTooltip]="page_count2 < count_total ? 'Pŕoxima página' : 'Sem próxima página'"
            [ngStyle]="{'opacity': page_count2 < count_total ? '1' : '0.4' }">
                <img src="/assets/icons/next.png" width="12px" height="12px">
            </div>
            
        </div>
    </div>

</div>