<div class="screen">
    <div class="div-login">

        <div class="logo-img">
            <img src="../../assets/images/Fast.png">
        </div>

        <div style="color: #FFD500; font-size: 23px; font-weight: 500; margin: 20px auto;">
            Login
        </div>

        <div style="height: 20px;"></div>

        <div class="div-input">
            <input type="email" matInput placeholder="Email" [(ngModel)]="email">
        </div>

        <div class="div-input">
            <input type="password" matInput placeholder="Senha" [(ngModel)]="password" (keyup.enter)="login()">
        </div>

        <!-- <div  routerLink="/forgot-password" class="card_forgot_password " style="cursor: pointer;">
            Esqueceu a senha?
        </div> -->

        <button class="btn-yellow" mat-raised-button (click)="login()" style="margin-top: 20px;">
            Entrar
            <span *ngIf="loading" class="spinner-border spinner-border-sm" 
            style="margin-left: 5px; color: black;" role="status" aria-hidden="true"></span>
        </button>
        <div style="margin: 5px 0 20px 0; text-align: center; width: 100%; color: red; height: 20px;">
            {{error_message}}
        </div>

    </div>
</div>