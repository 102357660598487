import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-preview-statistic',
  templateUrl: './preview-statistic.component.html',
  styleUrls: ['./preview-statistic.component.css']
})
export class PreviewStatisticComponent implements OnInit {

  constructor() { }

	title: string = "";
	text: any;

  ngOnInit(): void {
    console.log(this.text)
  }

}
