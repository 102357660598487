<div class="primary-div">

    <div class="screen-options white-card max-auto">
        
        <div class="left-side">
            <div class="screen-title">
                Estatísticas | {{event?.name}} - {{event?.startTime | date: 'dd/MM/yyyy'}}
            </div>
        </div>

        <div style="position: relative;"></div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <div class="item-card card-graphics">

            <div class="col-info" style="font-weight: 600;">
                <strong> Carro </strong> <br>
               Sócio Antecipado <br>
               Não Sócio Antecipado <br>

                Sócio Evento <br>
               Não Sócio Evento <br>

                Voucher <br>
                <span *ngIf="is_admin"> Cortesia </span> <br>
                                 
            </div>

            <div class="col-info">
                <strong> Quantidade </strong> <br>
                <span> 
                    {{event.cars.carAntecipated.partnerCarTransactionsTickets}}
                </span> <br>

                <span> 
                    {{event.cars.carAntecipated.noPartnerCarTransactionsTickets}}
                </span> <br>

                <span>
                    {{event.cars.carsEvent.partnerCarSalesTickets}}
                </span> <br>

                <span>
                    {{event.cars.carsEvent.noPartnerCarSalesTickets}}
                </span> <br>
                
                {{event.cars.carsVouchers.amount}}<br>
                 <span *ngIf="is_admin">
                   {{event.cars.carsGiveAway.amount}}  
                </span> <br>
              
            </div>

            <div class="col-info">
                <strong> Preço Unitário </strong> <br>
                <span>
                   {{convertMoney(event.cars.carAntecipated.partnerPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.cars.carAntecipated.unitaryPrice)}}
                 </span> <br>

                <span>
                  {{convertMoney(event.cars.carsEvent.partnerPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.cars.carsEvent.unitaryPrice)}}
                  </span> <br>

               {{convertMoney(event.cars.carsVouchers.unitaryPrice)}} <br>
                 <span *ngIf="is_admin">
                    {{convertMoney(event.cars.carsGiveAway.unitaryPrice)}}  
                </span> <br>         

            </div>
 

            <div class="col-info">
                <b> Total </b> <br>
                {{convertMoney(event.cars.carAntecipated.partnersTransactionsValue)}} <br>
                {{convertMoney(event.cars.carAntecipated.regularTransactionsValue)}} <br>

                {{convertMoney(event.cars.carsEvent.totalValue)}}<br>
                {{convertMoney(event.cars.carsEvent.regularSalesValue)}}<br>

 {{convertMoney(event.cars.carsVouchers.total)}}<br>
 <span *ngIf="is_admin"> {{convertMoney(event.cars.carsGiveAway.total)}} </span>
                
                
            </div>

        </div>

        <div class="item-card card-graphics">

            <div class="col-info" style="font-weight: 600;">
                <strong> Vans </strong> <br>
                Sócio Antecipado <br>
                Não Sócio Antecipado <br>
 
                 Sócio Evento <br>
                Não Sócio Evento <br>
 
                 Voucher <br>
                 <span *ngIf="is_admin"> Cortesia </span>

                               
            </div>

            <div class="col-info">
                <b> Quantidade </b> <br>
                <span> 
                    {{event.vans.vanAntecipated.partnerVanTransactionsTickets}}
                </span> <br>

                <span> 
                    {{event.vans.vanAntecipated.noPartnerVanTransactionsTickets}}
                </span> <br>

                <span>
                   {{event.vans.vansEvent.partnerVanSalesTickets}}
                </span> <br>

                <span>
                    {{event.vans.vansEvent.noPartnerVanSalesTickets}}
                 </span> <br>
                
                {{event.vans.vansVouchers.amount}}<br>
                 <span *ngIf="is_admin">
                   {{event.vans.vansGiveAway.amount}}  
                </span> <br>
              
            </div>

            <div class="col-info">
                <b> Preço Unitário </b> <br>
                <span>
                    {{convertMoney(event.vans.vanAntecipated.partnerPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.vans.vanAntecipated.unitaryPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.vans.vansEvent.partnerPrice)}}

                </span> <br>

                <span>
                    {{convertMoney(event.vans.vansEvent.unitaryPrice)}}

                </span> <br>

                {{convertMoney(event.vans.vansVouchers.unitaryPrice)}} <br>
                 <span *ngIf="is_admin">
                    {{convertMoney(event.vans.vansGiveAway.unitaryPrice)}}  
                </span> <br>         

            </div>
 

            <div class="col-info">
                <b> Total </b> <br>
                {{convertMoney(event.vans.vanAntecipated.partnersTransactionsValue)}} <br>
                {{convertMoney(event.vans.vanAntecipated.regularTransactionsValue)}} <br>

                {{convertMoney(event.vans.vansEvent.partnersSalesValue)}}<br>
                {{convertMoney(event.vans.vansEvent.regularSalesValue)}}<br>

                {{convertMoney(event.vans.vansVouchers.total)}}<br>
                <span *ngIf="is_admin">{{convertMoney(event.vans.vansGiveAway.total)}}  </span>
                
                
            </div>

        </div>

        <div class="item-card card-graphics">

            <div class="col-info" style="font-weight: 600;">
                <strong> Ônibus </strong> <br>
                Sócio Antecipado <br>
                Não Sócio Antecipado <br>
 
                 Sócio Evento <br>
                Não Sócio Evento <br>
 
                 Voucher <br>
                 <span *ngIf="is_admin">Cortesia </span>

                                
            </div>

            <div class="col-info">
                <b> Quantidade </b> <br>
                <span> 
                    {{event.bus.busAntecipated.partnerBusTransactionsTickets}}
                </span> <br>

                <span> 
                    {{event.bus.busAntecipated.noPartnerBusTransactionsTickets}}
                </span> <br>

                <span>
                    {{event.bus.busEvent.partnerBusSalesTickets}}
                </span> <br>

                <span>
                    {{event.bus.busEvent.noPartnerBusSalesTickets}}
                </span> <br>
                
                
                {{event.bus.busVouchers.amount}}<br>
                 <span *ngIf="is_admin">
                    {{event.bus.busGiveAway.amount}}  
                </span> <br>
              
            </div>

            <div class="col-info">
                <b> Preço Unitário </b> <br>
                <span>
                    {{convertMoney(event.bus.busAntecipated.partnerPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.bus.busAntecipated.unitaryPrice)}}
                </span> <br>


                <span>
                    {{convertMoney(event.bus.busEvent.partnerPrice)}}
                </span> <br>

                <span>
                    {{convertMoney(event.bus.busEvent.unitaryPrice)}}
                </span> <br>

                {{convertMoney(event.bus.busVouchers.unitaryPrice)}} <br>
                 <span *ngIf="is_admin">
                    {{convertMoney(event.bus.busGiveAway.unitaryPrice)}}    
                </span> <br>         

            </div>
 

            <div class="col-info">
                <b> Total </b> <br>
                {{convertMoney(event.bus.busAntecipated.partnersTransactionsValue)}} <br>
                {{convertMoney(event.bus.busAntecipated.regularTransactionsValue)}} <br>

                {{convertMoney(event.bus.busEvent.partnersSalesValue)}}<br>
                {{convertMoney(event.bus.busEvent.regularSalesValue)}}<br>

                {{convertMoney(event.bus.busVouchers.total)}}<br>
                <span *ngIf="is_admin">{{convertMoney(event.bus.busGiveAway.total)}} </span>
                 
                
            </div>

        </div>

    </div>

</div>