import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalNewVipComponent } from './modal-new-vip/modal-new-vip.component';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { VipService } from '../service/vip.service';
import { Vip } from '../model/vip';
import { ModalVipCouponsComponent } from './modal-vip-coupons/modal-vip-coupons.component';

@Component({
  	selector: 'app-vip',
  	templateUrl: './vip.component.html',
  	styleUrls: ['./vip.component.css']
})

export class VipComponent {

	constructor( 
		private router: Router,
		private vipService: VipService,
		private modalService: MDBModalService,
	) { }

	modalRef: MDBModalRef;
    
    displayedColumns: string[] = ['name', 'event', 'owner', 'car', 'quantity', 'option'];
    data_source = new MatTableDataSource<Vip>();

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;
    
    is_loaded: boolean = false;

    search_event: string;
    search_grantor: string;

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
        }
        else{
            this.getVips(1)
        }
	}

    getVips(page){
        if((this.search_event != null && this.search_event != "") || (this.search_grantor != null && this.search_grantor != "")){
			this.vipService.searchVip(page, this.page_size, this.search_event, this.search_grantor).subscribe( data => {
                this.setData(data, page)
            }, error => {
                localStorage.removeItem('token')
                this.router.navigate([''])
            })
		}
		else{
			this.vipService.getAllVip(page, this.page_size).subscribe( data => {
                this.setData(data, page)
            }, error => {
                localStorage.removeItem('token')
                this.router.navigate([''])
            })
		}
	}

    setData(data, page){
        this.is_loaded = true;
        this.data_source.data = data.vips;
        
        // pagination calc
        this.page = page;
        this.count_total = data.total;
        this.page_count1 = 1 + ((page-1) * this.page_size)
        if((this.page_count1 + (this.page_size-1)) > this.count_total){
            this.page_count2 = this.count_total;
        }
        else{
            this.page_count2 = this.page_count1 + (this.page_size-1);
        }
    }

	newVip(){
		this.modalRef = this.modalService.show(ModalNewVipComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
				title: "Novo pacote",
				btn_text: "Criar"
            }
        })
		this.modalRef.content.action.subscribe( (result: any) => { 
            if(result[0] == "update"){
                this.getVips(this.page)
			}
        });
	}

    vipCoupons(element){
		this.modalRef = this.modalService.show(ModalVipCouponsComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog modal-lg',
            containerClass: '',
            animated: false,
            data: {
				title: "Pacote",
				vip: element
            }
        })
	}

	deleteVip(vip: Vip){
        this.modalRef = this.modalService.show(ModalChoiceComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: "Tem certeza...",
                text: `de que deseja deletar o pacote?`
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "yes"){
                this.openMessage("Por favor, aguarde", "", true)
                this.vipService.deleteVip(vip._id).subscribe( data => {
                    this.modalRef.hide()
                    this.openMessage("Deletado!", "Pacote deletado com sucesso.", false)
					this.getVips(this.page)
                })
			}
        });
	}
	
	openMessage(title, text, bgclick: boolean){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

	next(){
		if(this.page_count2 < this.count_total && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getVips(this.page+1)
		}
	}

	prev(){
		if(this.page > 1 && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getVips(this.page-1)
		}
	}

}
