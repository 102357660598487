<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> Detalhes </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body">
        <table mat-table [dataSource]="data_source" class="table">
            <ng-container matColumnDef="isValid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Válido </th>
                <td mat-cell *matCellDef="let data"> {{data.isValid ? 'Sim' : 'Não'}} </td>
            </ng-container>

            <ng-container matColumnDef="used">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Usado </th>
                <td mat-cell *matCellDef="let data"> {{data.used ? 'Sim' : 'Não'}} </td>
            </ng-container>

            <ng-container matColumnDef="alreadyExchanged">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Trocado </th>
                <td  *matCellDef="let data"> {{data.alreadyExchanged ? 'Sim' : 'Não'}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

         
        
    </div>

</div>