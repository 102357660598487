import { Component, Input } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  	selector: 'modal-message',
  	templateUrl: './modal-message.component.html',
  	styleUrls: ['./modal-message.component.css']
})

export class ModalMessageComponent {

	constructor(public modalRef: MDBModalRef) { }

	action: Subject<any> = new Subject();
	text: string = "";
	title: string = "";

	ngOnInit() {
		
	}

	close(){
		this.action.next('no');
		this.modalRef.hide();
	}
	
	next(){
		this.action.next('yes');
		this.modalRef.hide();
	}
}