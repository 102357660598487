<div class="primary-div">

    <div class="screen-options white-card max-auto">
        
        <div class="left-side">
            <div class="screen-title">
                Cortesia 
            </div>
        </div>

        <div class="right-side">
            <input matInput placeholder="Busca por evento..." [(ngModel)]="search_event" matTooltip="Busque pelo nome do evento"
                class="input" autocomplete="off" (ngModelChange)="getGiveAway(1)">

            <input matInput placeholder="Busca por doador..." [(ngModel)]="search_grantor" matTooltip="Busque pelo nome do doador"
                class="input" autocomplete="off" (ngModelChange)="getGiveAway(1)">

            <button class="btn-yellow" mat-raised-button (click)="newGiveAway()">
                Novo cupom de cortesia
            </button>
        </div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <table mat-table [dataSource]="data_source" class="mat-elevation-z8 table-class">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Nome </th>
                <td mat-cell *matCellDef="let data"> {{data.giveAwayName != null ? data.giveAwayName : '-'}} </td>
            </ng-container>

            <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Evento </th>
                <td mat-cell *matCellDef="let data"> {{data.event?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Doador </th>
                <td mat-cell *matCellDef="let data"> {{data.userThatGaveAway?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="car">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Veículo </th>
                <td mat-cell *matCellDef="let data" > 
                    {{data.vehicle_type == 'car' ? 'Carro' :
                      data.vehicle_type == 'van' ? 'Van' : 
                      data.vehicle_type == 'bus' ? 'Ônibus' : data.vehicle_type}}
                </td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Quantidade </th>
                <td mat-cell *matCellDef="let data"> {{data.quantity}} </td>
            </ng-container>

            <ng-container matColumnDef="option">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px;"> </th>
                <td mat-cell *matCellDef="let data" style="width: 100px;"> 
                    <div style="display: flex;">
                        <img src="/assets/icons/email.png" class="edit-icon" 
                            matTooltip="Enviar cupom" (click)="giveAwayCoupons(data)">

                        <div class="delete-btn" matTooltip="Deletar cortesia?" (click)="deleteGiveAway(data)">
                            <img src="/assets/icons/delete.png" class="delete-icon">
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

        <div class="table-paginator">
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
            
            <div style="display: flex;">
                
                <div class="paginator-button prev" (click)="prev()" matTooltipPosition="above"
                [matTooltip]="page == 1 ? 'Sem página anterior' : 'Página anterior'"
                [ngStyle]="{'opacity': page == 1 ? '0.4' : '1' }">
                    <img src="/assets/icons/prev.png" width="12px" height="12px">
                </div>
                
                <div class="paginator-button next" (click)="next()" matTooltipPosition="above"
                [matTooltip]="page_count2 < count_total ? 'Pŕoxima página' : 'Sem próxima página'"
                [ngStyle]="{'opacity': page_count2 < count_total ? '1' : '0.4' }">
                    <img src="/assets/icons/next.png" width="12px" height="12px">
                </div>
                
			</div>
        </div>

    </div>

</div>