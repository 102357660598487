import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { UserService } from 'src/app/service/user.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  	selector: 'modal-change-password',
  	templateUrl: './modal-change-password.component.html',
  	styleUrls: ['./modal-change-password.component.css']
})

export class ModalChangePasswordComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private userService: UserService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;

    user_id: string;
    old_password: string;
    new_password: string;
    confirm_password: string;
    email: string = ""
    hash: string = "";
    password: string = "";
    validator = new ValidatorUtils()

	ngOnInit() {
		
    }
    
    checkPassword(){
        if( this.validator.isEmptyOrNull(this.old_password)){
            this.openMessage("Por favor,", "insira a senha atual.", false);
            return false
        }
        else if( this.validator.isEmptyOrNull(this.new_password)){
            this.openMessage("Por favor,", "insira a nova senha.", false);
            return false
        }
        else if(this.validator.isEmptyOrNull(this.confirm_password)){
            this.openMessage("Por favor,", "confirme a nova senha.", false);
            return false
        }
        else if(this.confirm_password != this.new_password){
            this.openMessage("Por favor,", "confirme a nova senha.", false);
            return false
        }
        else{

            let body = {
                "_id": this.user_id,
                "oldPassword": this.old_password,
                "newPassword": this.new_password
            }
            
            this.openMessage("Por favor, aguarde", "", true)
            this.userService.changeUserPassword(this.password, this.email, this.hash).subscribe( data => {
                this.modalRef2.hide()
                this.openMessage("Alterada!", "Senha alterada com sucesso.", false)
                this.modalRef.hide()
            }, error => {
                this.modalRef2.hide()
                this.error(error, 'alterar')
            })

        }
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} a senha.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}