import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { ValidatorUtils } from '../utils/validator.utils';
import { UserService } from '../service/user.service';
import { User } from '../model/user';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { ModalUserDetailComponent } from './modal-user-detail/modal-user-detail.component';

@Component({
  	selector: 'app-users',
  	templateUrl: './users.component.html',
  	styleUrls: ['./users.component.css']
})

export class UsersComponent {

	constructor( 
		private router: Router,
		private userService: UserService,
		private modalService: MDBModalService,
	) { }

	modalRef: MDBModalRef;
    
    displayedColumns: string[] = ['type', 'name', 'email', 'active', 'options'];
    data_source = new MatTableDataSource<User>();

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;
    
    is_loaded: boolean = false;

    user_types = [
        {name: 'Todos', apiName: ''},
        {name: 'Administrador', apiName: 'admin'},
        {name: 'Contratante', apiName: 'contractor'},
        {name: 'Gerente', apiName: 'manager'},
        {name: 'Usuário', apiName: 'user'},
        {name: 'Vendedor', apiName: 'seller'}
    ]
    filter_type = '';

    search: string;
    is_admin: boolean = false;
    is_manager: boolean = false;

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
        }
        else{
            this.getUsers(1)

            if(this.userService.user != null){
                if(this.userService.user.user_type == "admin"){
                    this.is_admin = true;
                }
                else if(this.userService.user.user_type == "manager"){
                    this.is_manager = true;
                }
            }
        }
    }

    getUsers(page){
        if(this.search != null && this.search != ""){
            this.filter_type = '';
            this.userService.searchUsers(page, this.page_size, this.search).subscribe( data => {
                this.setData(data, page)
            }, error => {
                // localStorage.removeItem('token')
                // this.router.navigate([''])
            })
        }
        else{
            this.userService.getAllUsers(page, this.page_size, this.filter_type).subscribe( data => {
                this.setData(data, page)
            }, error => {
                // localStorage.removeItem('token')
                // this.router.navigate([''])
            })
        }
	}

    setData(data, page){
        this.is_loaded = true;
        this.data_source.data = data.users;
        
        // pagination calc
        this.page = page;
        this.count_total = data.total;
        this.page_count1 = 1 + ((page-1) * this.page_size)
        if((this.page_count1 + (this.page_size-1)) > this.count_total){
            this.page_count2 = this.count_total;
        }
        else{
            this.page_count2 = this.page_count1 + (this.page_size-1);
        }
    }

    changeType(){
        this.getUsers(1)
    }

	detailUser(user){
        this.modalRef = this.modalService.show(ModalUserDetailComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: true,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                is_manager: this.is_manager,
				title: user == null ? "Criar usuário" : "Editar Usuário",
				btn_text: user == null ? "Criar" : "Salvar",
                user: user
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "update"){
                this.getUsers(this.page)
			}
        });
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }
	
	deleteUser(user){
        this.modalRef = this.modalService.show(ModalChoiceComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: false,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: "Tem certeza...",
                text: `de que deseja deletar o usuário ${user.name}?`
            }
        })
        this.modalRef.content.action.subscribe( (result: any) => { 
            if(result == "yes"){
                this.openMessage("Por favor, aguarde", "", true)
                this.userService.deleteUser(user._id).subscribe( data => {
                    this.modalRef.hide()
                    this.openMessage("Deletado!", "Usuário deletado com sucesso.", false)
					this.getUsers(this.page)
                }, error => {
                    this.modalRef.hide()
                    this.error(error, 'deletar')
                })
			}
        });
    }

    changeStatus(active, id){
        let body = {'_id': id, 'active': active}
        //console.log(body)
        this.userService.editUser(body).subscribe( data => {
        
        }, error => {
            this.error(error, 'editar')
        })
    }

    error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o usuário.`, msg, false)
    }

	next(){
		if(this.page_count2 < this.count_total && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getUsers(this.page+1)
		}
	}

	prev(){
		if(this.page > 1 && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getUsers(this.page-1)
		}
	}

}
