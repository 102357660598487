import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AllGiveAways, GiveAway } from "../model/giveaway";

@Injectable({
    providedIn: 'root'
})

export class GiveAwayService {

    constructor(private http : HttpClient) { }

    getAllGiveAway(page, pagesize) : Observable<AllGiveAways> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<AllGiveAways>(AppConfig.path + "giveaway/getAll/", options)
    }

    searchGiveAway(page, pagesize, event, grantor) : Observable<AllGiveAways> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        if(event != null && event != ""){
            options.params = options.params.append("eventName", event)
        }
        if(grantor != null && grantor != ""){
            options.params = options.params.append("grantorName", grantor)
        }

        return this.http.get<AllGiveAways>(AppConfig.path + "giveaway/search/", options)
    }

    createGiveAway(request_body): Observable<GiveAway>{
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<GiveAway>(AppConfig.path + "giveaway/create/", body, { headers })
    }

    deleteGiveAway(id): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('_id', id)
        }

        return this.http.get<any>(AppConfig.path + "giveaway/delete/", options)
    }

}