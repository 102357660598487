import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { Event } from 'src/app/model/event';
import { EventsService } from 'src/app/service/events.service';
import { UserService } from 'src/app/service/user.service';
import { VipService } from 'src/app/service/vip.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
    selector: 'modal-new-vip',
    templateUrl: './modal-new-vip.component.html',
    styleUrls: ['./modal-new-vip.component.css']
})

export class ModalNewVipComponent {

    constructor(
        public modalRef: MDBModalRef,
        private modalService: MDBModalService,
        private userService: UserService,
        private eventsService: EventsService,
        private vipService: VipService,
    ) { }

    action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;

    title: string = "";
    btn_text: string;
    is_loaded: boolean = false;

    validator = new ValidatorUtils();

    quantity: number = 1;
    name: string = "";
    events: Event[] = [];
    event: Event;
    vehicles = [
        { name: 'Carro', apiName: 'car' },
        { name: 'Van', apiName: 'van' },
        { name: 'Ônibus', apiName: 'bus' },
    ];
    vehicle;

    ngOnInit() {
        this.getEvents()
    }

    getEvents() {
        this.eventsService.searchEvent(1, 50, "", this.userService.user.user_type == "manager").subscribe(data => {
            this.events = data.events
            this.is_loaded = true;
        })
    }

    checkVip() {
        if (!this.is_loaded) {
            return
        }
        else if(this.validator.isEmptyOrNull(this.name)){
            this.openMessage("Por favor,", "adicione o nome do pacote.", false);
            return false
        }
        else if (this.event == null) {
            this.openMessage("Por favor,", "selecione o evento.", false);
            return false
        }
        else if (this.vehicle == null) {
            this.openMessage("Por favor,", "selecione o veículo.", false);
            return false
        }
        else if (this.quantity == null || this.quantity < 1) {
            this.openMessage("Por favor,", "insira a quantidade.", false);
            return false
        }
        else if (this.vehicle.apiName == 'car' && this.quantity > (this.event.carSpots - this.event.carSoldTickets)) {
            this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para carros no evento e \
            insira uma quantidade válida.", false);
            return false
        }
        else if (this.vehicle.apiName == 'van' && this.quantity > (this.event.vanSpots - this.event.vanSoldTickets)) {
            this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para vans no evento e \
            insira uma quantidade válida.", false);
            return false
        }
        else if (this.vehicle.apiName == 'bus' && this.quantity > (this.event.busSpots - this.event.busSoldTickets)) {
            this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para ônibus no evento e \
            insira uma quantidade válida.", false);
            return false
        }
        else {
            let body = {
                "name": this.name,
                "vehicleType": this.vehicle.apiName,
                "eventId": this.event._id,
                "quantity": this.quantity,
            }

            this.createVip(body)
        }
    }

    createVip(body) {
        this.openMessage("Por favor, aguarde", "", true)
        this.vipService.createVip(body).subscribe(data => {
            this.modalRef2.hide()
            this.openMessage("Criado!", "Pacote criado com sucesso.", false)
            this.action.next(['update', data]);
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

    error(error, string) {
        var msg = ""
        if (error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o pacote.`, msg, false)
    }

    openMessage(title, text, bgclick: boolean) {
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}