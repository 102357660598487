import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { ChartsModule } from 'ng2-charts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatSliderModule} from '@angular/material/slider';

import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavbarScreenComponent } from './navbar/navbar.component';
import { EventsComponent } from './events/events.component';
import { UsersComponent } from './users/users.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { ModalChoiceComponent } from './modals/modal-choice/modal-choice.component';
import { ModalUserDetailComponent } from './users/modal-user-detail/modal-user-detail.component';
import { EventGraphicComponent } from './event-graphic/event-graphic.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { StatisticsAdminComponent } from './events/statistics-admin/statistics-admin.component';
import { SalesComponent } from './sales/sales.component';
import { GiveAwayComponent } from './giveaway/giveaway.component';
import { ModalNewGiveAwayComponent } from './giveaway/modal-new-giveaway/modal-new-giveaway.component';
import { ModalGiveAwayCouponsComponent } from './giveaway/modal-giveaway-coupons/modal-giveaway-coupons.component';
import { StatisticsContractorComponent } from './events/statistics-contractor/statistics-contractor.component';
import { ModalChangePasswordComponent } from './modals/modal-change-password/modal-change-password.component';
import { ModalCouponsComponent } from './transactions/modal-coupons/modal-coupons.component';
import { TableComponent } from './events/table/table.component';
import { VipComponent } from './vip/vip.component';
import { ModalNewVipComponent } from './vip/modal-new-vip/modal-new-vip.component';
import { ModalVipCouponsComponent } from './vip/modal-vip-coupons/modal-vip-coupons.component';
import { TicketsComponent } from './tickets/tickets.component';
import { ModalSendTicketComponent } from './tickets/modal-send-ticket/modal-send-ticket.component';
import { PreviewStatisticComponent } from './modals/preview-statistic/preview-statistic.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ModalDetailsComponent } from './tickets/modal-details/modal-details.component';
registerLocaleData(localePt);

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		NavbarScreenComponent,
		EventsComponent,
		UsersComponent,
		EventDetailComponent,
		ModalMessageComponent,
		ModalChoiceComponent,
		ModalUserDetailComponent,
		EventGraphicComponent,
		TransactionsComponent,
		StatisticsAdminComponent,
		SalesComponent,
		GiveAwayComponent,
		ModalNewGiveAwayComponent,
		ModalGiveAwayCouponsComponent,
		StatisticsContractorComponent,
		ModalChangePasswordComponent,
		ModalCouponsComponent,
		TableComponent,
		VipComponent,
		ModalNewVipComponent,
		ModalVipCouponsComponent,
		TicketsComponent,
		ModalSendTicketComponent,
		ModalDetailsComponent,
		PreviewStatisticComponent,
		ChangePasswordComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		MatInputModule,
		BrowserAnimationsModule,
		MatButtonModule,
		MatSlideToggleModule,
		MatFormFieldModule,
		HttpClientModule,
		MatTableModule,
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatTooltipModule,
		MatSelectModule,
		ChartsModule,
		MatAutocompleteModule,
		MatNativeDateModule,
		MatIconModule,
		MatCheckboxModule,
		MatExpansionModule,
		NgxMatSelectSearchModule,
		MatSliderModule,
		CommonModule,
		NgxMaskModule.forRoot(),
		MDBBootstrapModule.forRoot()
	],
	providers: [
		DatePipe,
		MatNativeDateModule,
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
		{ provide: LOCALE_ID, useValue: "pt" }
	],
	bootstrap: [
		AppComponent
	]
})

export class AppModule { }
