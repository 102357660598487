import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(
        private userService: UserService,
        private router: Router
    ) { }

    loading: boolean = false;
    email: string;
    password: string;
    keepLoggedIn: boolean = true;

    error_message: string;
    
    ngOnInit(): void {
        localStorage.clear()
    }

    login(){
        this.error_message = "";

        if(this.email == null || this.email == ""){
            this.error_message = "Por favor, insira o email."
        }
        else if(this.password == null || this.password == ""){
            this.error_message = "Por favor, insira a senha."
        }
        else{
            this.loading = true;
            this.userService.login(this.email, this.password).subscribe( data => {

                if(
                    data.user.user_type == "admin" || 
                    data.user.user_type == "contractor" ||
                    data.user.user_type == "manager"
                ){
                    this.userService.user = data.user;
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('_id', data.user._id)
                    
                    this.router.navigate(['/home/events'])
                }
                else{
                    this.error_message = "Você não possui acesso à esta dashboard";
                    this.loading = false;
                }
               
            }, error => {

                this.loading = false;
                this.error_message = 
                    error.error.error == "User not found" ? "Usuário não encontrado" : 
                    error.error.error == "Bad password" ? "Senha incorreta" : 
                    "Usuário ou senha incorretos";
                
            })
        }

    }

}
