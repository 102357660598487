<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title">
            <b> {{title}} </b>
        </h1>

    </div>

    <div class="modal-body">

        {{text}}

        <div style="width: 100%; margin-top: 40px; margin-bottom: 5px;">
            <button mat-raised-button class="btn-white" (click)="close('no')"> 
                NÃO
            </button> 

            <button mat-raised-button class="btn-yellow" (click)="close('yes')"> 
                SIM
            </button> 
        </div>
        
    </div>

</div>