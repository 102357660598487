<div class="modal-content">

    <div class="modal-header">

        <h1 class="modal-title">
            <b> Ingressos </b>
        </h1>

    </div>

    <div class="modal-body" *ngIf="coupons.length <= 0">
        Nenhum ingresso.
    </div>

    <div class="modal-body" *ngIf="coupons.length > 0">
        <div class="div-row" style="font-weight: 600;">
            <div style="width: 50%; max-width: 200px;" class="text-ellipsis">
                Código
            </div>
            <div style="width: 20%; min-width: 65px;">
               Veículo
            </div>
            <div style="width: 15%; min-width: 65px">
                Enviado
            </div>
            <div style="width: 15%; min-width: 65px">
                Usado
            </div>
        </div>
        <div *ngFor="let coupon of coupons" class="div-row">
            <div style="width: 50%; max-width: 200px;" class="text-ellipsis">
                {{coupon.exchangeCode}}
            </div>
            <div style="width: 20%; min-width: 65px">
                {{  
                    coupon.vehicle_type == 'car' ? 'Carro' :
                    coupon.vehicle_type == 'van' ? 'Van' : 
                    coupon.vehicle_type == 'bus' ? 'Ônibus' : coupon.vehicle_type
                }}
            </div>
            <div style="width: 15%; min-width: 65px">
                {{coupon.alreadyExchanged || coupon.alreadySentToClientEmail ? 'Sim' : 'Não'}}
            </div>
            <div style="width: 15%; min-width: 65px">
                {{coupon.used ? 'Sim' : 'Não'}}
            </div>
        </div>
    </div>

</div>