import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { CustomEncoder } from "./custom-encoder";
import { Md5 } from "md5-typescript";

import { Login, User } from "../model/user";

@Injectable({
    providedIn: 'root'
})

export class UserService {

    constructor(private http : HttpClient) { }

    user: User;

    login(email, password) : Observable<Login> {
        const options = {
            params: new HttpParams()
                .set(`email`, email)
                .set(`password`, password),
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
        }

        return this.http.get<Login>(AppConfig.path + "user/auth", options)
    }

    getUser(id) : Observable<User> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<User>(AppConfig.path + "user/get/", options)
    }

    getAllUsers(page, pagesize, type) : Observable<any> {
        var options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        if(type != null && type != ""){
            options.params = options.params.set('userType', type)
        }

        return this.http.get<any>(AppConfig.path + "user/getAll/", options)
    }

    searchUsers(page, pagesize, name) : Observable<any> {
        var options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
                .set("name", name)
        }

        return this.http.get<any>(AppConfig.path + "user/search/", options)
    }

    deleteUser(user_id): Observable<any>{
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set('_id', user_id)
        }

        return this.http.get<any>(AppConfig.path + "user/delete/", options)
    }

    createUser(request_body): Observable<any>{
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "user/create/", body, { headers })
    }

    editUser(request_body): Observable<any>{
        let body: any = JSON.stringify(request_body)

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })
            .set('Authorization', `Bearer ${localStorage.getItem('token')}`)

        return this.http.post<any>(AppConfig.path + "user/edit/", body, { headers })
    }

    changeUserPassword(new_password: any, email: any, hash: any): Observable<any>{

        let body: any = JSON.stringify({
              new_password: new_password,
              email: email,
              forgot_password_hash: hash,
        })

        var headers = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http.patch<any>(AppConfig.path + "user/change-forgotten-password", body, { headers })
    }
    

}