import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { Coupon } from 'src/app/model/giveaway';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CouponService } from 'src/app/service/coupon.service';

@Component({
  	selector: 'modal-send-ticket',
  	templateUrl: './modal-send-ticket.component.html',
  	styleUrls: ['./modal-send-ticket.component.css']
})

export class ModalSendTicketComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private couponService: CouponService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    is_loaded: boolean = false;

    displayedColumns: string[] = ['code', 'email'];
    data_source = new MatTableDataSource<Coupon>();

    coupon: Coupon;

    validator = new ValidatorUtils();
    email: string;

	ngOnInit() {
        this.data_source.data = [this.coupon]
    }

    send(){
        if(this.validator.isEmptyOrNull(this.email)){
            this.openMessage('Por favor,', 'insira o email', false)
            return false
        }
        else{
            this.openMessage("Por favor, aguarde", "", true)
            this.couponService.exchangeCoupon(this.email, this.coupon.exchangeCode,
                this.coupon.eventName, this.coupon.s3ImageName).subscribe( data => {
                    this.modalRef2.hide()
                    this.coupon.alreadySentToClientEmail = true;
                    this.openMessage("Enviado!", "Email enviado com sucesso.", false)
            }, error => {
                this.modalRef2.hide()
                this.error(error, 'enviar')
            })
        }
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o email.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}