<div class="primary-div">

    <div class="screen-options white-card max-auto">
        
        <div class="left-side">
            <div class="screen-title">
                Resumo | {{event?.name}} - {{event?.startTime | date: 'dd/MM/yyyy'}}
            </div>
        </div>

        <div class="right-side">
            <div style="margin-left: 15px; display: flex;">
                <a href="/home/event/statistics/{{event?._id}}" matTooltip="Estatísticas">
                    <img src="/assets/icons/bars2.png" class="edit-icon" style="margin-right: 8px;">
                </a>

                <a href="/home/event/edit/{{event?._id}}" matTooltip="Editar evento">
                    <img src="/assets/icons/edit.png" class="edit-icon">
                </a>
            </div>
        </div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <div class="item-card card-texts">

            <div class="title">
                Total de Vagas: {{ event.capacity }}
            </div>

            <div class="col-info" style="font-weight: 600;">
                <br><br>
                Carros <br>
                Vans <br>
                Ônibus <br>
                Cortesia
            </div>

            <div class="col-info">
                <b> Vendidos </b> <br><br>
                {{ event.carSaleTicketQuantity + event.carTransactionTicketQuantity }} <br>
                {{ event.vanSaleTicketQuantity + event.vanTransactionTicketQuantity }} <br>
                {{ event.busSaleTicketQuantity + event.busTransactionTicketQuantity }} <br>
                {{ event.carGiveAwayTicketQuantity + event.vanGiveAwayTicketQuantity + event.busGiveAwayTicketQuantity }}
            </div>

            <div class="col-info">
                <b> Valor </b> <br><br>
                {{ convertMoney(event.carPrice) }} <br>
                {{ convertMoney(event.vanPrice) }} <br>
                {{ convertMoney(event.busPrice) }} <br>
                {{ convertMoney(0) }}
            </div>

            <div class="col-info">
                <b> Arrecadado <br> presencial </b> <br>
                {{ convertMoney(event.carSaleTicketQuantity * event.carPrice) }} <br>
                {{ convertMoney(event.vanSaleTicketQuantity * event.vanPrice) }} <br>
                {{ convertMoney(event.busSaleTicketQuantity * event.busPrice) }} <br>
                {{ convertMoney(0) }}
            </div>

            <div class="col-info">
                <b> Arrecadado <br> online </b> <br>
                {{ convertMoney(event.carTransactionTicketQuantity * event.carPrice) }} <br>
                {{ convertMoney(event.vanTransactionTicketQuantity * event.vanPrice) }} <br>
                {{ convertMoney(event.busTransactionTicketQuantity * event.busPrice) }} <br>
                {{ convertMoney(0) }}
            </div>

            <div class="col-info">
                <b> Arrecadado <br> total </b> <br>
                {{ convertMoney((event.carSaleTicketQuantity + event.carTransactionTicketQuantity) * event.carPrice) }} <br>
                {{ convertMoney((event.vanSaleTicketQuantity + event.vanTransactionTicketQuantity) * event.vanPrice) }} <br>
                {{ convertMoney((event.busSaleTicketQuantity + event.busTransactionTicketQuantity) * event.busPrice) }} <br>
                {{ convertMoney(0) }}
            </div>

            <div class="col-info">
                <b> Pacote </b> <br><br>
                {{ event.carVipTicketQuantity }}  <br>
                {{ event.vanVipTicketQuantity }}  <br>
                {{ event.busVipTicketQuantity }}  <br>
                {{ event.carVipTicketQuantity + event.vanVipTicketQuantity + event.busVipTicketQuantity }}
            </div>

            <div class="col-info">
                <b> Acessados </b> <br><br>
                {{ event.parkedCars }} <br>
                {{ event.parkedVans }} <br>
                {{ event.parkedBus }} <br>
                {{ event_used.totalGiveAway }}
            </div>

        </div>

    </div>

    <div style="padding: 0 0 15px; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <div class="item-card card-graphics">
            <div class="title">
                Gráficos
            </div>

            <div class="graphic">
                <event-graphic [barChartData]="barChartDataCars" [biggest]="biggest.car"></event-graphic>

                <h1>Carros</h1>
                <div class="legend">
                    <div style="background-color: #FFD500;" class="ball"></div> Total de vendidos
                </div>
                <div class="legend">
                    <div style="background-color: #252525;" class="ball"></div> Total de acessados
                </div>
            </div>
            
            <div class="graphic">
                <event-graphic [barChartData]="barChartDataVans" [biggest]="biggest.van"></event-graphic>

                <h1>Vans</h1>
                <div class="legend">
                    <div style="background-color: #FFD500;" class="ball"></div> Total de vendidos
                </div>
                <div class="legend">
                    <div style="background-color: #252525;" class="ball"></div> Total de acessados
                </div>
            </div>
            
            <div class="graphic">
                <event-graphic [barChartData]="barChartDataBus" [biggest]="biggest.bus"></event-graphic>

                <h1>Ônibus</h1>
                <div class="legend">
                    <div style="background-color: #FFD500;" class="ball"></div> Total de vendidos
                </div>
                <div class="legend">
                    <div style="background-color: #252525;" class="ball"></div> Total de acessados
                </div>
            </div>

            <div class="graphic">
                <event-graphic [barChartData]="barChartDataGiveAway" [biggest]="biggest.giveAway"></event-graphic>

                <h1>Cortesia</h1>
                <div class="legend">
                    <div style="background-color: #FFD500;" class="ball"></div> Total
                </div>
                <div class="legend">
                    <div style="background-color: #252525;" class="ball"></div> Total de acessados
                </div>
            </div>

        </div>

    </div>

</div>