<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar" 
	style="overflow-x: hidden !important; font-size: 20px !important" #navbar>

    <mdb-navbar-brand> <img src="assets/images/logo.png" routerLink="/home"
    style="width: auto; height: 5vh; cursor: pointer; outline: none;"> </mdb-navbar-brand>
  
	<links>
		<ul class="navbar-nav ml-auto" style="font-size: 18px !important">
			
			<li class="nav-item" *ngIf="is_admin || is_manager">
				<a mdbWavesEffect routerLink="/home/users" routerLinkActive="yellow-text" class="nav-link white-text"> 
					Usuários
				</a>
            </li>
            
			<li class="nav-item" >
				<a mdbWavesEffect routerLink="/home/events" routerLinkActive="yellow-text" class="nav-link white-text"> 
					Eventos
				</a>
			</li>

			<li dropdown class="nav-item" *ngIf="is_admin || is_manager">
				<a dropdownToggle type="button" mdbWavesEffect style="display: flex; align-items: center; font-weight: 600;"
					class="nav-link waves-light" 
					[ngStyle]="{'color': url == '/home/sales' || url == '/home/transactions' ? '#FFD500' : 'white' }"> 
					Vendas <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon>
				</a>

				<div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown" style="min-width: 220px;">
					<a class="dropdown-item white-text" routerLink="/home/transactions"
						routerLinkActive="yellow-text" style="font-weight: 600;"> 
						Vendas online
					</a>

					<div style="width: 100%; margin: 10px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.5);"></div>
					
					<a class="dropdown-item white-text" routerLink="/home/sales" 
						routerLinkActive="yellow-text" style="font-weight: 600;"> 
						Vendas presenciais
					</a>
              	</div>
            </li>

			<li class="nav-item" *ngIf="is_admin || is_manager">
				<a mdbWavesEffect routerLink="/home/tickets" routerLinkActive="yellow-text" class="nav-link white-text"> 
					Ingressos
				</a>
			</li>

			<li class="nav-item" *ngIf="is_admin">
				<a mdbWavesEffect routerLink="/home/giveaway" routerLinkActive="yellow-text" class="nav-link white-text"> 
					Cortesia
				</a>
			</li>

			<li class="nav-item" *ngIf="is_admin || is_manager">
				<a mdbWavesEffect routerLink="/home/vip" routerLinkActive="yellow-text" class="nav-link white-text"> 
					Pacote
				</a>
			</li>
            
            <li dropdown class="nav-item nav-item-small">
				<a dropdownToggle type="button" mdbWavesEffect class="waves-light">
					<img src="/assets/images/user.png" class="user-image">
				</a>

				<div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown" style="min-width: 220px;">
					<div style="color: white; text-align: center; padding: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.5);">
						{{user?.name}} <br>
						{{  user?.user_type == 'admin' ? 'Administrador' : (
							user?.user_type == 'manager' ? 'Gerente' : (
							user?.user_type == 'seller' ? 'Vendedor' :
							user?.user_type == 'contractor' ? 'Contratante' : user?.user_type
						)) }} 
					</div>

					<a class="dropdown-item white-text" (click)="changePassword()" style="font-weight: 600;"> 
						Alterar senha
					</a>
					
					<a class="dropdown-item white-text" routerLink="/login" (click)="logout()" style="font-weight: 600;"> 
						Sair 
					</a>
              	</div>
            </li>
            
		</ul>
    </links>
    
</mdb-navbar>

<router-outlet *ngIf="loaded"></router-outlet>