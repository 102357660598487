import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { ModalMessageComponent } from '../modals/modal-message/modal-message.component';
import { ModalCouponsComponent } from './modal-coupons/modal-coupons.component';
import { TransactionsService } from '../service/transactions.service';
import { Transaction } from '../model/transaction';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalChoiceComponent } from '../modals/modal-choice/modal-choice.component';

@Component({
	selector: 'app-transactions',
	templateUrl: './transactions.component.html',
	styleUrls: ['./transactions.component.css']
})

export class TransactionsComponent {

	constructor(
		private router: Router,
		private transactionsService: TransactionsService,
		private modalService: MDBModalService,
		private datePipe: DatePipe,
	) { }

	modalRef: MDBModalRef;

	displayedColumns: string[] = ['user', 'event', 'value', 'type', 'partner', 'brand', 'date', 'status', 'authorizationCode', 'tId', 'proofOfSale', 'option'];
	data_source = new MatTableDataSource<Transaction>();

	/* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
	page_size = 10;

	is_loaded: boolean = false;

	search_user: string;
	search_event: string;

	range = new FormGroup({
		start: new FormControl(),
		end: new FormControl()
	});

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			// this.router.navigate(['']);
			console.log('a');
		}
		else {
			this.getTransactions(1, false)
		}
	}

	getTransactions(page, date_change: boolean) {
		if ((this.search_event != null && this.search_event != "") ||
			(this.search_user != null && this.search_user != "") ||
			((this.range.value.start != null && this.range.value.start != "") &&
				(this.range.value.end != null && this.range.value.end != ""))) {
			var aux_lower = this.range.value.end == null ? null : this.range.value.end.setDate(this.range.value.end.getDate() + 1)
			var greater = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd")
			var lower = this.datePipe.transform(aux_lower, "yyyy-MM-dd")

			this.transactionsService.searchTransactions(page, this.page_size, this.search_event, this.search_user,
				greater, lower).subscribe(data => {
					this.setData(data, page)
				}, error => {
					localStorage.removeItem('token')
					// this.router.navigate([''])
					console.log('a');
				})
		}
		else if (!date_change) {
			this.transactionsService.getAllTransactions(page, this.page_size).subscribe(data => {
				this.setData(data, page)
			}, error => {
				localStorage.removeItem('token')
				// this.router.navigate([''])
				console.log('a');
			})
		}
	}

	setData(data, page) {
		this.is_loaded = true;
		this.data_source.data = data.transactions;

		// pagination calc
		this.page = page;
		this.count_total = data.total;
		this.page_count1 = 1 + ((page - 1) * this.page_size)
		if ((this.page_count1 + (this.page_size - 1)) > this.count_total) {
			this.page_count2 = this.count_total;
		}
		else {
			this.page_count2 = this.page_count1 + (this.page_size - 1);
		}
	}

	getTransactionsCoupon(element) {
		this.openMessage("Por favor, aguarde", "", true)
		this.transactionsService.getTransactionCoupons(element._id).subscribe(data => {
			this.modalRef.hide()
			this.openModalCoupons(data)
		}, error => {
			this.modalRef.hide()
			this.openMessage("Erro,", "ocorreu um erro ao tentar ver os ingressos da venda", false)
		})
	}

	openModalCoupons(data) {
		this.modalRef = this.modalService.show(ModalCouponsComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: false,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				coupons: data.coupons
			}
		})
	}

	openMessage(title, text, bgclick: boolean) {
		this.modalRef = this.modalService.show(ModalMessageComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: bgclick,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				title: title,
				text: text
			}
		})
	}

	cancelTransaction(obj) {
		console.log(obj);

		this.modalRef = this.modalService.show(ModalChoiceComponent, {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: true,
			ignoreBackdropClick: false,
			class: 'modal-dialog-centered modal-dialog',
			containerClass: '',
			animated: false,
			data: {
				title: "Tem certeza...",
				text: `de que deseja cancelar e reembolsar o valor do ingresso ${obj.eventName}?`
			}
		})
		this.modalRef.content.action.subscribe((result: any) => {
			if (result == "yes") {
				console.log('->',obj);
				this.cancelCreditCardTransaction(obj._id)
			}
		});
	}

	cancelCreditCardTransaction(id) {
        console.log(id);

		this.transactionsService.cancelCreditCardTransaction(id).subscribe(data => {
			console.log(data)

			this.openMessage("Sucesso!", "venda cancelada! ", false)
			
			this.getTransactions(1, false)
		}, error => {
			console.log(error)
			// console.log(error.error.error)

			if(error.status == 491 || error.status == 492){
				this.openMessage("Erro", "transação nao está disponivel ", false)
			}
			else{
				this.openMessage("Erro", "erro no cancelamento da venda! ", false)
			}
		})
	}



	clearDate(event) {
		event.stopPropagation();
		this.range.reset()
		this.range.value.end = null;
		this.range.value.start = null;
		this.getTransactions(1, false)
	}

	next() {
		if (this.page_count2 < this.count_total && this.is_loaded) {
			this.data_source.data = []
			this.is_loaded = false;
			this.getTransactions(this.page + 1, false)
		}
	}

	prev() {
		if (this.page > 1 && this.is_loaded) {
			this.data_source.data = []
			this.is_loaded = false;
			this.getTransactions(this.page - 1, false)
		}
	}

	convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
	}

}
