import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../service/user.service";
@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    try {
      
      this.route.queryParams.subscribe((params) => {
        this.email = params['email'];
        this.hash = params['hash'];
      });
    } catch {
      // this.router.navigate([""]);
    }
  }
  loading = false;
  email: string = ""
  hash: string = "";
  password: string = "";
  confirm_password: string = "";
  msg_error: string = "";
  msg_errorNewPassword: string = "";
  msg_errorConfirmPassword: string = "";
  warningMap = new Map();
  success_message = ""

  change: any = {
    id:  null,
    new_password: "",
    confirm_password: "",
  };

  text_button: string = "CONFIRMAR";

  ngOnInit(): void {
  }


  checkInput() {
    this.warningMap = new Map();
    this.msg_error = "";

    this.warningMap = new Map();

    if ((this.password == null ||this.password == "") &&
      (this.confirm_password == null ||this.confirm_password == "")) 
    {
      this.warningMap.set("password", true);
      this.warningMap.set("confirm_password", true);
      this.msg_error = "Preencha os campos para definir uma nova senha";
    }
    else if (this.password == null || this.password == "") {
      this.warningMap.set("password", true);
      this.msg_error = "Preencha o campo senha";
      
    } else if (this.confirm_password == null || this.confirm_password == "") {
      this.warningMap.set("confirm_password", true);
      this.msg_error = "Preencha o campo confirmar senha";
    }
    else if (this.password != this.confirm_password) {
      this.warningMap.set("password", true);
      this.warningMap.set("confirm_password", true);
      this.msg_error = "As senhas não coincidem";
    }

    if (this.warningMap.size == 0 && !this.loading) {
      this.changePassword();
    }
  }

  changePassword() {
    this.loading = true
    this.userService.changeUserPassword(this.password, this.email, this.hash).subscribe((data) => {     
      this.loading = false
      if(data["result"] === 'Password Updated') {
        this.success_message = "Senha alterada com sucesso!"


        setTimeout(() => {
          this.router.navigate([""])
        }, 2000)
      }
    });
  }
}
