import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MDBModalRef } from 'angular-bootstrap-md';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { ValidatorUtils } from '../../utils/validator.utils';
import { UserService } from '../../service/user.service';
import { Color } from 'ng2-charts';

@Component({
  	selector: 'app-statistics-admin',
  	templateUrl: './statistics-admin.component.html',
  	styleUrls: ['./statistics-admin.component.css']
})

export class StatisticsAdminComponent {

	constructor( 
		private router: Router,
		private route: ActivatedRoute,
		private eventsService: EventsService,
		private userService: UserService
	){
		try{
            this.id = this.route.snapshot.paramMap.get('id');
        } catch{
            this.router.navigate(['/home/events'])
        }
	}

	modalRef: MDBModalRef;
	
	id;
	event: Event;
	is_loaded: boolean = false;
	
	//graphic
    barChartDataCars;
    barChartDataVans;
    barChartDataBus;
	barChartDataGiveAway;
    biggest = {van: 10, car: 10, bus: 10, giveAway: 10}

	graphicColor: Color[] = [
        {backgroundColor: '#B1B1B1'},
        {backgroundColor: '#252525'},
    ]

	event_used = {
		totalGiveAway: 0,
		totalSale: 0,
		totalTransaction: 0
	}

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
        }
        else{
			if(this.userService.user.user_type != "admin") 
				this.router.navigate(['/home/events'])

            this.getEvent()
        }
    }

    getEvent(){
        this.eventsService.getEvent(this.id).subscribe( data => {
			this.eventsService.getEventParkedQuantity(this.id, true).subscribe( data2 => {
				this.event = data;
				this.event_used = data2;
				this.setEvent(data2)
			})
        }, error => {
			localStorage.removeItem('token')
			this.router.navigate([''])
		})
	}

	setEvent(data2){
		this.barChartDataCars = [
			{ data: [this.event.carTransactionTicketQuantity + this.event.carSaleTicketQuantity], label: 'Vendidos' },
			{ data: [this.event.parkedCars], label: 'Acessados' },
			{ data: [this.event.carVipTicketQuantity], label: 'Pacote' }
		];

		this.barChartDataVans = [
			{ data: [(this.event.vanTransactionTicketQuantity + this.event.vanSaleTicketQuantity)], label: 'Vendidos' },
			{ data: [this.event.parkedVans], label: 'Acessados' },
			{ data: [this.event.vanVipTicketQuantity], label: 'Pacote' }
		];

		this.barChartDataBus = [
			{ data: [(this.event.busTransactionTicketQuantity + this.event.busSaleTicketQuantity)], label: 'Vendidos' },
			{ data: [this.event.parkedBus], label: 'Acessados' },
			{ data: [this.event.busVipTicketQuantity], label: 'Pacote' }
		];

		this.barChartDataGiveAway = [
			{ data: [(
				this.event.carGiveAwayTicketQuantity + 
				this.event.vanGiveAwayTicketQuantity +
				this.event.busGiveAwayTicketQuantity
			)], label: 'Total' },
			{ data: [data2.totalGiveAway], label: 'Acessados' },
			{ data: [(
				this.event.carVipTicketQuantity + 
				this.event.vanVipTicketQuantity + 
				this.event.busVipTicketQuantity
			)], label: 'Pacote' }
		];

		this.biggest.car = (this.event.carTransactionTicketQuantity + this.event.carSaleTicketQuantity) * 1.2;	
		this.biggest.van = (this.event.vanTransactionTicketQuantity + this.event.vanSaleTicketQuantity) * 1.2;
		this.biggest.bus = (this.event.busTransactionTicketQuantity + this.event.busSaleTicketQuantity) * 1.2;
		this.biggest.giveAway = (
			this.event.carGiveAwayTicketQuantity + 
			this.event.vanGiveAwayTicketQuantity +
			this.event.busGiveAwayTicketQuantity
		) * 1.2;

		this.is_loaded = true;
	}

	convertMoney(money) {
		let validator = new ValidatorUtils()
		return validator.convertMoney(money)
	}

}
