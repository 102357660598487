<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> {{title}} - {{giveaway.event.name}} </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body">

        <table mat-table [dataSource]="data_source" class="table">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Código </th>
                <td mat-cell *matCellDef="let data"> {{data.exchangeCode}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Email </th>
                <td mat-cell *matCellDef="let data; let i = index"> 
                    <input matInput placeholder="insira o email..." [(ngModel)]="email[i]" class="input" 
                        autocomplete="off"[readonly]="data.used || data.alreadySentToClientEmail" >
                </td>
            </ng-container>

            <ng-container matColumnDef="send">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px;"> </th>
                <td mat-cell *matCellDef="let data; let i = index" style="width: 100px;"> 
                    <button class="btn-yellow" mat-button *ngIf="!data.alreadySentToClientEmail" (click)="send(data, i)">
                        ENVIAR
                    </button>

                    <button class="btn-yellow" mat-button *ngIf="data.alreadySentToClientEmail">
                        JÁ FOI ENVIADO
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>
        
    </div>

</div>