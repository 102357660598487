import { Component } from '@angular/core';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { Event } from 'src/app/model/event';
import { EventsService } from 'src/app/service/events.service';
import { GiveAwayService } from 'src/app/service/giveaway.service';
import { UserService } from 'src/app/service/user.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';

@Component({
  	selector: 'modal-new-giveaway',
  	templateUrl: './modal-new-giveaway.component.html',
  	styleUrls: ['./modal-new-giveaway.component.css']
})

export class ModalNewGiveAwayComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private eventsService: EventsService,
        private giveAwayService: GiveAwayService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    btn_text: string;
    is_loaded: boolean = false;

    validator = new ValidatorUtils();

    name: string = "";
    quantity: number = 1;
    events: Event[] = [];
    event: Event;
    vehicles = [
        {name: 'Carro', apiName: 'car'},
        {name: 'Van', apiName: 'van'},
        {name: 'Ônibus', apiName: 'bus'},
    ];
    vehicle;

	ngOnInit() {
		this.getEvents()
    }

    getEvents(){
        this.eventsService.searchEvent(1, 50, "", false).subscribe( data => {
            this.events = data.events
            this.is_loaded = true;
        })
    }

    checkGiveAway(){
        if(!this.is_loaded){
            return
        }
        else if(this.event == null){
            this.openMessage("Por favor,", "selecione o evento.", false);
            return false
        }
        else if(this.vehicle == null){
            this.openMessage("Por favor,", "selecione o veículo.", false);
            return false
        }
        else if(this.quantity == null || this.quantity < 1){
            this.openMessage("Por favor,", "insira a quantidade.", false);
            return false
        }
        // else if(this.vehicle.apiName == 'car' && this.quantity > (this.event.carSpots - this.event.carSoldTickets)){
        //     this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para carros no evento e \
        //     insira uma quantidade válida.", false);
        //     return false
        // }
        // else if(this.vehicle.apiName == 'van' && this.quantity > (this.event.vanSpots - this.event.vanSoldTickets)){
        //     this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para vans no evento e \
        //     insira uma quantidade válida.", false);
        //     return false
        // }
        // else if(this.vehicle.apiName == 'bus' && this.quantity > (this.event.busSpots - this.event.busSoldTickets)){
        //     this.openMessage("Por favor,", "verifique as informações de ingressos disponíveis para ônibus no evento e \
        //     insira uma quantidade válida.", false);
        //     return false
        // }
        else{
            let body = {
                "name": this.name,
                "vehicleType" : this.vehicle.apiName,
                "eventId" : this.event._id,
                "quantity": this.quantity
            }

            this.createGiveAway(body)
        }
    }

    createGiveAway(body){
        this.openMessage("Por favor, aguarde", "", true)
        this.giveAwayService.createGiveAway(body).subscribe( data => {
            this.modalRef2.hide()
            this.openMessage("Criada!", "Cortesia criada com sucesso.", false)
            this.action.next(['update', data]);
            this.modalRef.hide()
        }, error => {
            this.modalRef2.hide()
            this.error(error, 'criar')
        })
    }

	error(error, string){
        var msg = ""
        if(error.error != null && error.error.error != null && error.error.error.length < 50)
            msg = error.error.error;
        else
            msg = "Por favor verifique as informações inseridas"
        this.openMessage(`Erro, não foi possível ${string} o cupom.`, msg, false)
    }

	openMessage(title, text, bgclick: boolean){
        this.modalRef2 = this.modalService.show(ModalMessageComponent, {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            ignoreBackdropClick: bgclick,
            class: 'modal-dialog-centered modal-dialog',
            containerClass: '',
            animated: false,
            data: {
                title: title,
                text: text
            }
        })
    }

}