import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';
import { ModalMessageComponent } from 'src/app/modals/modal-message/modal-message.component';
import { Coupon } from 'src/app/model/giveaway';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CouponService } from 'src/app/service/coupon.service';

@Component({
  	selector: 'modal-details',
  	templateUrl: './modal-details.component.html',
  	styleUrls: ['./modal-details.component.css']
})

 

export class ModalDetailsComponent {

	constructor(
        public modalRef: MDBModalRef, 
        private modalService: MDBModalService,
        private couponService: CouponService,
    ) { }

	action: Subject<any> = new Subject();
    modalRef2: MDBModalRef;
    
    title: string = "";
    is_loaded: boolean = false;

    displayedColumns: string[] = ['isValid', 'used', 'alreadyExchanged'];
    data_source = new MatTableDataSource<Coupon>();

    coupon: Coupon;

    validator = new ValidatorUtils();
    email: string;

	ngOnInit() {
        this.data_source.data = [this.coupon]


        console.log({
            a: this.coupon
        })
    }	 

}