<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> {{title}} </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body custom-scrollbar">

        <mat-label> Nome* </mat-label>
        <input matInput placeholder="nome" [(ngModel)]="new_user.name" class="input" autocomplete="off">

        <mat-label> Email* </mat-label>
        <input matInput placeholder="email" [(ngModel)]="new_user.email" class="input" autocomplete="off">

        <mat-label> Data de nascimento </mat-label>
        <input matInput placeholder="data de nascimento" [(ngModel)]="new_user.birthDate" class="input" 
            autocomplete="off" mask="00/00/0000" [dropSpecialCharacters]="false">

        <mat-label> CPF </mat-label>
        <input matInput placeholder="cpf" [(ngModel)]="new_user.cpf" class="input" autocomplete="off" 
            [dropSpecialCharacters]="false" mask="000.000.000-00">

        <mat-label> Telefone </mat-label>
        <input matInput placeholder="telefone" [(ngModel)]="new_user.cellphone" class="input" autocomplete="off" 
            mask="(00) 00000-0000" [dropSpecialCharacters]="false">

        <mat-label> Tipo do usuário* </mat-label>
        <mat-select class="input" [(value)]="new_user.user_type">
            <mat-option *ngFor="let option of user_types" [value]="option.apiName">
                {{option.name}}
            </mat-option>
        </mat-select>

        <mat-label *ngIf="is_new"> Senha* </mat-label>
        <input *ngIf="is_new" matInput placeholder="senha" [(ngModel)]="new_user.password" class="input" autocomplete="off" type="password">

        <mat-label *ngIf="is_new"> Confirmar senha* </mat-label>
        <input *ngIf="is_new" matInput placeholder="confirmar senha" [(ngModel)]="new_user.confirm_password" class="input" autocomplete="off" type="password">
        
    </div>

    <div style="text-align: center;">
        <button class="btn-yellow" mat-button (click)="checkUser()">
            {{btn_text}}
        </button>
    </div>

</div>