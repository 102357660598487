<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> {{title}} </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body" *ngIf="!is_loaded" style="align-items: center; padding: 30px 10px 50px;">
        <span class="spinner-border spinner-border-sm" style="color: black;"
            role="status" aria-hidden="true"></span>
    </div>

    <div class="modal-body" *ngIf="is_loaded">

        <mat-label> Nome </mat-label>
        <input matInput [(ngModel)]="name" class="input" autocomplete="off">
        
        <mat-label> Quantidade </mat-label>
        <input matInput [(ngModel)]="quantity" class="input" mask="0000000000000">

        <mat-label> Evento </mat-label>
        <mat-select class="input" [(value)]="event">
            <mat-option *ngFor="let option of events" [value]="option">
                {{option.name}} - {{option.startTime | date: 'dd/MM/yyyy'}}
            </mat-option>
        </mat-select>

        <mat-label> Veículo </mat-label>
        <mat-select class="input" [(value)]="vehicle">
            <mat-option *ngFor="let option of vehicles" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-select>
        
    </div>

    <div style="text-align: center;">
        <button class="btn-yellow" mat-button (click)="checkGiveAway()">
            {{btn_text}}
        </button>
    </div>

</div>