<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> Alterar senha </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body custom-scrollbar">

        <mat-label> Senha atual </mat-label>
        <input matInput placeholder="senha atual" [(ngModel)]="old_password" 
            class="input" autocomplete="off" type="password">

        <mat-label> Nova senha </mat-label>
        <input matInput placeholder="nova senha" [(ngModel)]="new_password" 
            class="input" autocomplete="off" type="password">

        <mat-label> Confirmar nova senha </mat-label>
        <input matInput placeholder="confirmar senha" [(ngModel)]="confirm_password" 
            class="input" autocomplete="off" type="password">
        
    </div>

    <div style="text-align: center;">
        <button class="btn-yellow" mat-button (click)="checkPassword()">
            Salvar
        </button>
    </div>

</div>