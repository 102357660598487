<div class="primary-div">

    <div class="screen-options white-card max-auto">
        
        <div class="left-side">
            <div class="screen-title">
                Usuários
            </div>
        </div>

        <div class="right-side"> 
            <input matInput placeholder="Busca..." [(ngModel)]="search" matTooltip="Busque pelo nome do usuário"
                class="input" autocomplete="off" (ngModelChange)="getUsers(1)">

            <button class="btn-yellow" mat-raised-button (click)="detailUser(null)">
                Novo usuário
            </button>
        </div>

    </div>

    <div style="padding: 50px; text-align: center;" *ngIf="!is_loaded" class="max-auto">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div style="padding: 15px 0; overflow-x: auto;" *ngIf="is_loaded" class="max-auto">

        <table mat-table [dataSource]="data_source" class="mat-elevation-z8 table-class">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > 

                    <mat-select class="input" [(value)]="filter_type" (selectionChange)="changeType()" 
                        style="width: 80%;">
                        <mat-option *ngFor="let option of user_types" [value]="option.apiName">
                            {{option.name}}
                        </mat-option>
                    </mat-select>
                    
                </th>
                <td mat-cell *matCellDef="let data"> 
                    {{  data.user_type == 'user' ? 'Usuário' : (
                        data.user_type == 'admin' ? 'Administrador' : (
                        data.user_type == 'manager' ? 'Gerente' : (
                        data.user_type == 'seller' ? 'Vendedor' :
                        data.user_type == 'contractor' ? 'Contratante' : data.user_type
                    ))) }} 
                </td>
            </ng-container>
            
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Nome </th>
                <td mat-cell *matCellDef="let data"> {{data.name}} </td>
            </ng-container>
    
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Email </th>
                <td mat-cell *matCellDef="let data"> {{data.email}} </td>
            </ng-container>

            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Ativo </th>
                <td mat-cell *matCellDef="let data">
                    <mat-slide-toggle 
                        [(ngModel)]="data.active" 
                        (change)="changeStatus(data.active, data._id)"
                        [disabled]="is_manager && data.user_type == 'admin'"
                    ></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 100px;"> </th>
                <td mat-cell *matCellDef="let data" style="width: 100px;"> 
                    <div style="display: flex; justify-content: center;">
                        <img src="/assets/icons/edit.png" class="edit-icon" *ngIf="is_admin || data.user_type != 'admin'"
                            matTooltip="Editar usuário" (click)="detailUser(data)">
                            
                        <div class="delete-btn" matTooltip="Deletar usuário?" 
                            (click)="deleteUser(data)" *ngIf="is_admin">
                            <img src="/assets/icons/delete.png" class="delete-icon">
                        </div>
                    </div>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

        <div class="table-paginator">
            <div>
                Mostrando {{page_count1}}-{{page_count2}} de {{count_total}}
            </div>
            
            <div style="display: flex;">
                
                <div class="paginator-button prev" (click)="prev()" matTooltipPosition="above"
                [matTooltip]="page == 1 ? 'Sem página anterior' : 'Página anterior'"
                [ngStyle]="{'opacity': page == 1 ? '0.4' : '1' }">
                    <img src="/assets/icons/prev.png" width="12px" height="12px">
                </div>
                
                <div class="paginator-button next" (click)="next()" matTooltipPosition="above"
                [matTooltip]="page_count2 < count_total ? 'Pŕoxima página' : 'Sem próxima página'"
                [ngStyle]="{'opacity': page_count2 < count_total ? '1' : '0.4' }">
                    <img src="/assets/icons/next.png" width="12px" height="12px">
                </div>
                
			</div>
        </div>

    </div>

</div>