<div class="primary-div">

    <div class="screen-options white-card max-auto">

        <div class="left-side">
            <div class="screen-title">
                {{title}}
            </div>
        </div>

        <div class="right-side">
            <button class="btn-yellow" mat-raised-button (click)="checkEvent()">
                {{button_text}}
            </button>
            <div style="margin-left: 15px; display: flex;">
                <a href="/home/event/statistics/{{event?._id}}" matTooltip="Estatísticas">
                    <img src="/assets/icons/bars2.png" class="edit-icon" style="margin-right: 8px;">
                </a>

                <a href="/home/event/resume/{{event?._id}}" matTooltip="Resumo">
                    <img src="/assets/icons/eye2.png" class="edit-icon" style="margin-right: 8px;">
                </a>
            </div>
        </div>

    </div>

    <div class="cards-row max-auto">

        <div class="item-card card-fields">

            <div class="line-text">
                <mat-label> Nome* </mat-label>
                <input matInput placeholder="nome" [(ngModel)]="event.name" class="input" autocomplete="off">

                <mat-label> Imagem* </mat-label>
                <input matInput placeholder="imagem" [(ngModel)]="event.image" class="input" readonly
                    (click)="fileInput.click()">
                <input type="file" #fileInput style="display: none;" accept="image/*" (change)="changePhoto($event)">
            </div>

            <div class="line-text">
                <div style="display: flex;">
                    <div style="width: calc(50% - 3px); margin-right: 6px;" class="div-date">
                        <mat-label> Data de início* </mat-label>
                        <input matInput placeholder="" class="input" readonly>
                        <mat-form-field class="input-date" color="accent">
                            <input matInput [matDatepicker]="picker1" autocomplete="off" [(ngModel)]="startDate"
                                readonly (click)="picker1.open()">
                            <mat-datepicker-toggle #picker1Toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div style="width: calc(50% - 3px)">
                        <mat-label> Horário de início* </mat-label>
                        <input matInput placeholder="hh:mm" [(ngModel)]="startHour" class="input" autocomplete="off"
                            mask="00:00">
                    </div>
                </div>

                <div style="display: flex;">
                    <div style="width: calc(50% - 3px); margin-right: 6px;" class="div-date">
                        <mat-label> Data de fim* </mat-label>
                        <input matInput placeholder="" class="input" readonly>
                        <mat-form-field class="input-date" color="accent">
                            <input matInput [matDatepicker]="picker2" autocomplete="off" [(ngModel)]="endDate" readonly
                                (click)="picker2.open()">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div style="width: calc(50% - 3px)">
                        <mat-label> Horário de fim* </mat-label>
                        <input matInput placeholder="hh:mm" [(ngModel)]="endHour" class="input" autocomplete="off"
                            mask="00:00">
                    </div>
                </div>
            </div>

            <div class="line-text">
                <mat-label> Descrição </mat-label>
                <textarea matInput placeholder="descrição" [(ngModel)]="event.description" class="input"
                    autocomplete="off" style="height: 95px; resize: none;"></textarea>
            </div>

            <div class="line-text">
                <mat-label> Local* </mat-label>
                <input matInput placeholder="local" [(ngModel)]="event.local" class="input" autocomplete="off">

                <mat-label> Rua </mat-label>
                <input matInput placeholder="rua" [(ngModel)]="event.street" class="input" autocomplete="off">
            </div>

            <div class="line-text">
                <div style="display: flex;">
                    <div style="width: calc(100% - 105px); margin-right: 5px;">
                        <mat-label> Bairro </mat-label>
                        <input matInput placeholder="bairro" [(ngModel)]="event.bairro" class="input"
                            autocomplete="off">
                    </div>

                    <div style="width: 100px;">
                        <mat-label> Número </mat-label>
                        <input matInput placeholder="número" [(ngModel)]="event.localNumber" class="input"
                            autocomplete="off" mask="000000000000000">
                    </div>
                </div>

                <mat-label> Cidade </mat-label>
                <input matInput placeholder="cidade" [(ngModel)]="event.city" class="input" autocomplete="off">
            </div>

            <div class="line-text">
                <mat-label> Complemento </mat-label>
                <input matInput placeholder="complemento" [(ngModel)]="event.complement" class="input"
                    autocomplete="off">

                <mat-label> Capacidade* </mat-label>
                <input matInput placeholder="capacidade" [(ngModel)]="event.capacity" class="input" autocomplete="off"
                    mask="000000000000000">
            </div>

            <div style="display: flex;" class="line-text">
                <div style="width: calc(50% - 3px); margin-right: 6px;">
                    <mat-label> Preço - Carro* </mat-label>
                    <input matInput placeholder="preço" [(ngModel)]="event.carPrice" class="input" autocomplete="off"
                        mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ ">
                </div>

                <div style="width: calc(50% - 3px)">
                    <mat-label> Vagas - Carro* </mat-label>
                    <input matInput placeholder="vagas" [(ngModel)]="event.carSpots" class="input" autocomplete="off">
                </div>
            </div>

            <div style="display: flex;" class="line-text">
                <div style="width: calc(50% - 3px); margin-right: 6px;">
                    <mat-label> Preço - Van* </mat-label>
                    <input matInput placeholder="preço" [(ngModel)]="event.vanPrice" class="input" autocomplete="off"
                        mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ ">
                </div>

                <div style="width: calc(50% - 3px)">
                    <mat-label> Vagas - Van* </mat-label>
                    <input matInput placeholder="vagas" [(ngModel)]="event.vanSpots" class="input" autocomplete="off">
                </div>
            </div>

            <div style="display: flex;" class="line-text">
                <div style="width: calc(50% - 3px); margin-right: 6px;">
                    <mat-label> Preço - ônibus* </mat-label>
                    <input matInput placeholder="preço" [(ngModel)]="event.busPrice" class="input" autocomplete="off"
                        mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" prefix="R$ ">
                </div>

                <div style="width: calc(50% - 3px)">
                    <mat-label> Vagas - ônibus* </mat-label>
                    <input matInput placeholder="vagas" [(ngModel)]="event.busSpots" class="input" autocomplete="off">
                </div>
            </div>

            <div class="line-text">
                <mat-label> Contratante* </mat-label>
                <mat-select class="input" [(value)]="contractor" (selectionChange)="changeContractor(contractor)">
                    <mat-option value="novo"> Novo usuário </mat-option>
                    <mat-option *ngFor="let option of contractors" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </div>

            <div class="line-text">
                <mat-label> Evento privado </mat-label>
                <mat-select class="input" [(value)]="event.isPrivate">
                    <mat-option [value]="true"> Sim </mat-option>
                    <mat-option [value]="false"> Não </mat-option>
                </mat-select>
            </div>

            <div class="line-text">
                <mat-checkbox [(ngModel)]="event.isPartnerDiscountAvailable">
                    <mat-label class="text_checkbox"> Disponibilizar desconto para sócio
                        torcedor do botafogo </mat-label>
                </mat-checkbox>

                <section *ngIf="event.isPartnerDiscountAvailable == true">
                    <mat-slider [(ngModel)]="event.partnerDiscountPercentage" step="0.1" min="0.1" max="0.9"
                        class="range">
                    </mat-slider>
                    <mat-label style="margin-left: 15px;" class="example-value-label">
                        {{event.partnerDiscountPercentage * 100}}% </mat-label>
                </section>

            </div>

        </div>

        <div class="item-card card-preview">

            <img [src]="event.image == null && send_image == null ? '/assets/images/futebol.jpg' : (
                    send_image != null && send_image.length > 0 ? send_image[0] : event.image)"
                class="event-image center-cropped">

            <div style="padding: 20px; color: white;">

                <h1 style="color: #FFD500; font-weight: 600;">
                    {{event.name == null || event.name == "" ? "Nome do evento" : event.name}}
                </h1>

                {{event.local == null || event.local == "" ? "local do evento" : event.local}} <br>
                {{event.description == null || event.description == "" ? "" : event.description}} <br><br>

                <h1 style="text-align: center; font-weight: 600; font-size: 40px; margin-bottom: 0;">
                    {{startHour == null ? 'hh:mm' : (startHour.substring(0,2) + ':' + startHour.substring(2,4))}}
                </h1>
                <h2 style="text-align: center;">
                    {{startDate == null ? 'dd/mm/aaaa' : (startDate | date: 'dd/MM/yyyy')}}
                </h2>

            </div>

        </div>

    </div>

</div>