import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AllTransactions } from "../model/transaction";

@Injectable({
    providedIn: 'root'
})

export class TransactionsService {

    constructor(private http: HttpClient) { }

    getAllTransactions(page, pagesize): Observable<AllTransactions> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        return this.http.get<AllTransactions>(AppConfig.path + "transaction/getAll/", options)
    }

    searchTransactions(page, pagesize, event, user, greater, lower): Observable<AllTransactions> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("limit", pagesize)
                .set("page", page)
        }

        if (event != null && event != "") {
            options.params = options.params.append("eventName", event)
        }
        if (user != null && user != "") {
            options.params = options.params.append("userName", user)
        }
        if (greater != null && greater != "" && lower != null && lower != "") {
            options.params = options.params.append("gte", greater)
            options.params = options.params.append("lt", lower)
        }

        return this.http.get<AllTransactions>(AppConfig.path + "transaction/search/", options)
    }

    getTransactionCoupons(transaction_id): Observable<any> {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("transactionId", transaction_id)
        }

        return this.http.get<any>(AppConfig.path + "coupon/getCouponByTransactionId/", options)
    }

    cancelCreditCardTransaction(id): Observable<any> {
        console.log(id);
        
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),
            params: new HttpParams()
                .set("_id", id)
        }

        return this.http.get<any>(AppConfig.path + "transaction/cancelCreditCardTransaction/", options)
    }

    cancelCreditCardTransactionPartial(transactionId, ticketId): Observable<any> {        
        const options = {
            headers: new HttpHeaders()
                .set('Authorization', `Bearer ${localStorage.getItem('token')}`),            
        }

        return this.http.put<any>(AppConfig.path + "transaction/cancelCreditCardTransactionPartial/", {
            transactionId,
            couponId: ticketId
        }, options)
    }

}