<div class="modal-content">

    <div class="modal-header">
        <h1 class="modal-title">
            <b> {{title}} - {{coupon.eventName}} </b>
        </h1>
        <img src="/assets/icons/close.png" class="close" (click)="modalRef.hide()">
    </div>

    <div class="modal-body">

        <table mat-table [dataSource]="data_source" class="table">
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Código </th>
                <td mat-cell *matCellDef="let data"> {{data.exchangeCode}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header > Email </th>
                <td mat-cell *matCellDef="let data"> 
                    <input matInput placeholder="insira o email..." [(ngModel)]="email" class="input" 
                        autocomplete="off" [readonly]="data.used" >
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>
        </table>

        <div style="text-align: center;">
            <button class="btn-yellow" mat-button *ngIf="!coupon.used" (click)="send()">
                {{ coupon.alreadySentToClientEmail ? 'ENVIADO, ENVIAR EMAIL NOVAMENTE?' : 'ENVIAR' }}
            </button>

            <button class="btn-yellow" mat-button *ngIf="coupon.used">
                JÁ FOI USADO
            </button>
        </div>
        
    </div>

</div>