import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { SalesService } from '../service/sales.service';
import { Sale } from '../model/sale';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UserService } from '../service/user.service';

@Component({
  	selector: 'app-sales',
  	templateUrl: './sales.component.html',
  	styleUrls: ['./sales.component.css']
})

export class SalesComponent {

	constructor( 
		private router: Router,
		private salesService: SalesService,
		private datePipe: DatePipe,
		private userService: UserService
	) { }
    
    displayedColumns: string[] = ['event', 'value', 'quantity', 'code', 'car', 'type', 'partner', 'userthatsold', 'date',];
    data_source = new MatTableDataSource<Sale>();

    /* pagination related variables */
	page: number = 1
	count_total: number = 0;
	page_count1: number = 0;
	page_count2: number = 0;
    page_size = 10;
    
    is_loaded: boolean = false;

	search_event: string;
	search_seller: string;

	range = new FormGroup({
		start: new FormControl(),
		end: new FormControl()
	});

	is_admin: boolean = false;
	is_manager: boolean = false;

	ngOnInit() {
		if (localStorage.getItem('token') == null) {
			this.router.navigate(['']);
        }
        else{
            this.getSales(1, false)

			if(this.userService.user != null){
                if(this.userService.user.user_type == "admin"){
                    this.is_admin = true;
                }
                else if(this.userService.user.user_type == "manager"){
                    this.is_manager = true;
                }
            }
        }
    }

    getSales(page, date_change: boolean){
		if( (this.search_event != null && this.search_event != "") || 
			(this.search_seller != null && this.search_seller != "") ||
			((this.range.value.start != null && this.range.value.start != "") &&
			(this.range.value.end != null && this.range.value.end != "")) )
		{
			var aux_lower = this.range.value.end == null ? null : this.range.value.end.setDate(this.range.value.end.getDate() + 1)
			var greater = this.datePipe.transform(this.range.value.start, "yyyy-MM-dd")
			var lower = this.datePipe.transform(aux_lower, "yyyy-MM-dd")
			
			this.salesService.searchSales(page, this.page_size, this.search_event, this.search_seller, 
			greater, lower).subscribe( data => {
				this.setData(data, page)
			}, error => {
				localStorage.removeItem('token')
				this.router.navigate([''])
			})
		}
		else if(!date_change){
			this.salesService.getAllSales(page, this.page_size).subscribe( data => {
				this.setData(data, page)
			}, error => {
				localStorage.removeItem('token')
				this.router.navigate([''])
			})
		}
	}

	setData(data, page){
		this.is_loaded = true;
		this.data_source.data = data.sales;
		
		// pagination calc
		this.page = page;
		this.count_total = data.total;
		this.page_count1 = 1 + ((page-1) * this.page_size)
		if((this.page_count1 + (this.page_size-1)) > this.count_total){
			this.page_count2 = this.count_total;
		}
		else{
			this.page_count2 = this.page_count1 + (this.page_size-1);
		}
	}

	clearDate(event) {
		event.stopPropagation();
		this.range.reset()
		this.range.value.end = null;
		this.range.value.start = null;
		this.getSales(1, false)
	}

	next(){
		if(this.page_count2 < this.count_total && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getSales(this.page+1, false)
		}
	}

	prev(){
		if(this.page > 1 && this.is_loaded){
			this.data_source.data = []
			this.is_loaded = false;
			this.getSales(this.page-1, false)
		}
	}

	convertMoney(money) {
		var formatter = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		});
		return formatter.format(money);
	}

}
